import { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../providers/UserProvider";

const LOGIN_DEFAULT_VALUES = {
  email: "",
  password: "",
};

export default function useLogin() {
  const [values, setValues] = useState(LOGIN_DEFAULT_VALUES);
  const [errors, setErrors] = useState(null);
  const { storeLoggedinUser } = useContext(UserContext);
  const history = useHistory();
  let location = useLocation();

  /**
   * Function to handle the form values on change.
   *
   * @param e
   */
  function onChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  /**
   * Function to handle the submit when someone logs in.
   *
   * @param e
   * @returns {Promise<any>}
   */
  async function onSubmit(e) {
    let { from } = location.state || { from: { pathname: "/" } };
    e.preventDefault();
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values }),
    };
    try {
      const fetchResponse = await fetch(global.api + "user/login", settings);
      const data = await fetchResponse.json();

      localStorage.setItem("token", data.data.token);

      if (data.errors) {
        setErrors(data.errors);
      }

      if (data.data.user) {
        storeLoggedinUser(data.data.user);
        history.replace("/");
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  return {
    onChange,
    onSubmit,
    values,
    errors,
  };
}
