import React, { useState } from "react";
import { Link } from "react-router-dom";
import useHeaderSlider from "../hooks/useHeaderSlider";
import Loading from "../loading/Loading";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export default function BusinessPoints() {
  return (
    <div className="businesspoints">
      <div className="businesspoints__wrapper container">
        <div className="businesspoints__list">
          <div className="businesspoints__list-item">
            <i className="ri-truck-line"></i>
            <p>Gratis bezorging</p>
          </div>
          <div className="businesspoints__list-item">
            <i className="ri-tools-line"></i>
            <p>Maatwerk print</p>
          </div>
          <div className="businesspoints__list-item">
            <i className="ri-shopping-bag-3-line"></i>
            <p>Eenvoudig bestellen</p>
          </div>
          <div className="businesspoints__list-item">
            <i className="ri-award-line"></i>
            <p>Gegarandeerde kwaliteit</p>
          </div>
        </div>
        <div className="businesspoints__mobile">
          <AliceCarousel
            autoPlay="true"
            autoPlayInterval="5000"
            disableDotsControls="false"
            disableButtonsControls="false"
            infinite="true"
          >
            <div className="businesspoints__item">
              <i className="ri-truck-line"></i>
              <p>Gratis bezorging</p>
            </div>
            <div className="businesspoints__item">
              <i className="ri-tools-line"></i>
              <p>Maatwerk print</p>
            </div>
            <div className="businesspoints__item">
              <i className="ri-shopping-bag-3-line"></i>
              <p>Eenvoudig bestellen</p>
            </div>
            <div className="businesspoints__item">
              <i className="ri-award-line"></i>
              <p>Gegarandeerde kwaliteit</p>
            </div>
          </AliceCarousel>
        </div>
      </div>
    </div>
  );
}
