import React, { useContext, useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import useProduct from "../hooks/useProduct";
import useProductPriceCalculation from "../hooks/useProductPriceCalculation";
import useShoppingCart from "../hooks/useShoppingCart";
import Loading from "../loading/Loading";
import SmallLoading from "../loading/SmallLoading";

export function ChoiceSummary(props) {
  const {
    choiceData,
    availableChoices,
    activeChoice,
    chosenChoices,
    customFile,
    onSubmit,
    selectedAccesoires,
  } = props;
  const { addToCart } = useShoppingCart();
  const {
    getPrice,
    getPriceId,
    configurationPrice,
  } = useProductPriceCalculation();
  const [clicked, setClicked] = useState(false);
  const { product } = useProduct();

  useEffect(() => {
    getPriceId(chosenChoices, selectedAccesoires);
  }, [availableChoices, chosenChoices, selectedAccesoires]);

  function mapObject(object, callback) {
    return Object.keys(object).map(function (key) {
      return callback(key, object[key]);
    });
  }

  function handleClickSubmit(event) {
    event.preventDefault();
    setClicked(true);
    onSubmit(choiceData?.next.id, clicked);
  }

  return (
    <div className="choicesummary">
      <div className="choicesummary__heading">
        <div className="choicesummary__heading__inner">
          <span className="choicesummary__heading-title">
            {choiceData?.name}
          </span>
          <p className="choicesummary__heading-subtitle">
            Hieronder vind u een samenvatting van uw samenstelling
          </p>
        </div>
        <div className="choice__heading-closebutton">
          {activeChoice === choiceData?.id ? (
            <i class="ri-arrow-up-s-line"></i>
          ) : (
            <i class="ri-arrow-down-s-line"></i>
          )}
        </div>
      </div>
      <div className="choicesummary__wrapper configurator__choice-card">
        <div className="choicesummary__inner">
          <div className="choicesummary__image">
            {customFile ? (
              <img className="choicesummary__image-img" src={customFile} />
            ) : (
              <img
                className="choicesummary__image-img"
                src={`https://shop.qustomshop.workers.dev/${product?.images[0].url}`}
              />
            )}
          </div>
          <div className="choicesummary__content">
            <h3 className="choicesummary__content-title">{product?.name}</h3>
            {mapObject(availableChoices, function (key, value) {
              return (
                <div className="choicesummary__values test">
                  <p className="choicesummary__values-name">{key}: </p>
                  <p className="choicesummary__values-value">{value}</p>
                </div>
              );
            })}
            {selectedAccesoires
              ? selectedAccesoires.map((accesoires) => {
                  return (
                    <div className="choicesummary__values test">
                      <p className="choicesummary__values-name">Accesoires: </p>
                      <p className="choicesummary__values-value">
                        {accesoires.name}
                      </p>
                    </div>
                  );
                })
              : undefined}
            <div className="choicesummary__price">
              <h4 className="choicesummary__price-title">Totaal bedrag:</h4>
              {configurationPrice ? (
                configurationPrice.data.price === -1 ? (
                  <p className="choicesummary__price-text">Op aanvraag</p>
                ) : (
                  <p className="choicesummary__price-text">
                    € {configurationPrice.data.price.toFixed(2)}
                  </p>
                )
              ) : (
                <SmallLoading />
              )}
            </div>
          </div>
        </div>
        {configurationPrice ? (
          configurationPrice.data.price === -1 ? undefined : (
            <div className="choicesummary__button">
              <button
                className={`${
                  clicked ? "clicked" : "notclicked"
                } choice__button btn btn--primary`}
                onClick={handleClickSubmit}
              >
                <span>Upload een bestand</span>
              </button>
            </div>
          )
        ) : (
          <SmallLoading />
        )}
      </div>
    </div>
  );
}
