import React, {useState} from "react";
import {Link, useHistory} from 'react-router-dom';
import useCategories from "../hooks/useCategories";

export default function NavbarMobile(props) {
    const {setMobileNavigation} = props;
    const {categories} = useCategories();
    const [openHover, setOpenHover] = useState(false);
    const history = useHistory();

    function toggleOpenHover(categoryName) {
        setOpenHover(categoryName);
    }

    function handleRedirect(categoryId, productID) {
        setMobileNavigation(false);
        history.push("/"+categoryId+"/"+productID)
    }
    return (
        <nav id="site-header-mobile" className="site-header-mobile">
            <div className="site-header-mobile__wrapper">
                <div className="site-header-mobile__navigation">
                    <ul className="navigation__menu">
                        {/* <li className="navigation__menu-item">
                          <Link to="/products" id="navigation__menu-link" className="navigation__menu-link">Alle producten </Link>
                          <i class="ri-arrow-down-s-line"></i>
                        </li> */}
                        {categories?.categories.map((category) =>
                            <li className="navigation__menu-item">
                                <Link id="navigation__menu-link" className={`${openHover === category.name ? "active" : "inactive"} navigation__menu-link`} onClick={() => toggleOpenHover(category.name)}>
                                    {category.name}     
                                    <i class="ri-arrow-down-s-line"></i>
                                </Link>
                                <div className={`${openHover === category.name ? "open" : "closed"} navigation__menu-hover`}>       
                                    {/* <Link to={"/"+category.id} className="menu__hover-title">{category.name}</Link> */}
                                    {category.products.map((product) =>
                                        <div className="menu__hover">
                                            <a onClick={() => handleRedirect(category.id, product.id)} className="menu__hover-link">{product.name}</a>
                                        </div>
                                    )}
                                </div>
                                
                            </li>
                        )}
                    </ul>
                </div>
            </div>
        </nav>
    )
}
