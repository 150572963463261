import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function Confirmation() {

    useEffect(() => {
        localStorage.clear();
    }, []);

    return (
        <div id="confirmation" className="confirmation container">
           <div className="confirmation__message">
            <i className="ri-check-line"></i>
            <h1 className="confirmation__message-title">Uw bestelling is geplaatst</h1>
            <p className="confirmation__message-subtitle">Wij gaan er zo snel mogelijk mee aan de slag en u krijgt een mailtje met verdere informatie</p>
            {/* <div className="confirmation__button">
                <Link to="/orders"  className="confirmation__button-btn btn btn--primary">
                    <span>Bekijk orders<i class="ri-arrow-right-line"></i></span>
                </Link>
            </div> */}
           </div>
        </div>
    )
}