import { useState, useEffect } from "react";

export default function useHeaderSlider() {
  const [items, setItems] = useState();
  const [loading, setLoading] = useState(true);

  /**
   * useEffect to get a specific category with the correct products.
   *
   */
  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch user from api
        await fetch(global.api + "sliders", {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        })
          .then((res) => res.json())
          .then((item) => {
            // If there is something in the response add the response to the state
            if (item) {
              setItems(item);
            }
          });
      } catch (err) {
        return err;
      } finally {
        // Finally, set the loading state to false
        setLoading(false);
      }
    }
    fetchData();
  }, []);

  return {
    items,
    loading,
  };
}
