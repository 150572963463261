import { useState } from "react";
import { useHistory } from "react-router-dom";

export default function useOrder() {
  const [order, setOrder] = useState();
  const [newOrderState, setNewOrderState] = useState();
  const [loading, setLoading] = useState(true);

  async function placeOrder(orderState) {
    localStorage.setItem("order", JSON.stringify(orderState));
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(orderState),
    };
    try {
      const fetchResponse = await fetch(
        global.api + "checkout/placeorder",
        settings
      );
      const data = await fetchResponse.json();
      setOrder(data);
      orderState = {
        ...orderState,
        order: { ...orderState.order, id: data.data.orderid },
      };
      localStorage.setItem("order", JSON.stringify(orderState));
      window.location.href = data.data.paymentUrl;
      return data;
    } catch (e) {
      return e;
    } finally {
      setLoading(false);
    }
  }

  return {
    placeOrder,
    order,
    loading,
  };
}
