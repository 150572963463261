export function generateCategoryUrl(category) {
  return `/category/${category.id}-${category.name}`;
}

export function generateProductUrl(product) {
  return `/product/1-productname`;
}

export function mapObject(object, callback) {
  return Object.keys(object).map(function (key) {
    return callback(key, object[key]);
  });
}