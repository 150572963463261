import React from "react";
import { Link } from "react-router-dom";
import useShoppingCart from "../hooks/useShoppingCart";

export default function Footer() {
  const { showShoppingCartPopUp, setShowShoppingPopUp } = useShoppingCart();

  const handleClick = () => setShowShoppingPopUp(!showShoppingCartPopUp);

  const onOutSideClick = () => setShowShoppingPopUp(false);

  return (
    <div className="footer">
      <div className="footer__wrapper">
        <div className="footer__content container">
          <div className="navigation__list">
            <p className="navigation__list-title">Klantenservice</p>
            <li className="navigation__list-item">
              <a
                href="mailto:klantenservice@qustomprints.nl"
                className="navigation__list-link"
              >
                <i className="ri-mail-line"></i> klantenservice@qustomprints.nl
              </a>
            </li>
            <li className="navigation__list-item">
              <a href="tel:085-3035631" className="navigation__list-link">
                <i className="ri-phone-line"></i> 085-3035631
              </a>
            </li>
          </div>
          <ul className="navigation__list">
            <p className="navigation__list-title">Zelf regelen</p>
            <li className="navigation__list-item">
              <Link to="/info/retourneren" className="navigation__list-link">
                Retourneren
              </Link>
            </li>
            <li className="navigation__list-item">
              <Link
                to="/info/garantie-beleid"
                className="navigation__list-link"
              >
                Klacht & Garantie
              </Link>
            </li>
            <li className="navigation__list-item">
              <Link to="/info/levering" className="navigation__list-link">
                Levering
              </Link>
            </li>
            <li className="navigation__list-item">
              <Link
                to="/info/bestellen-betaling"
                className="navigation__list-link"
              >
                Bestellen & betaling
              </Link>
            </li>
          </ul>
          <ul className="navigation__list">
            <p className="navigation__list-title">Navigatie</p>
            <li className="navigation__list-item">
              <a onClick={handleClick} className="navigation__list-link">
                Winkelmandje
              </a>
            </li>
            <li className="navigation__list-item">
              <Link to="/info/contact" className="navigation__list-link">
                Contact
              </Link>
            </li>
          </ul>
          <div className="navigation__list">
            <p className="navigation__list-title">Review</p>
            <p className="navigation__list-subtitle">Beoordeling klanten</p>
            <iframe
              src="https://dashboard.webwinkelkeur.nl/webshops/widget_html?id=1210793&layout=new_button_logo-score&theme=dark&color=%23ec008b&show=no&view=list&amount=6&width=manual&width_amount=81px&height=45px&interval=5000&language=nld"
              scrolling="no"
              className="wwk-widget-iframe wwk-widget--new_button_logo-score wwk-widget--new_button_logo-score--dark wwk-widget--has-tooltip"
              style={{
                border: 0,
                height: "45px",
                width: "'81px",
                marginTop: "10px",
              }}
            ></iframe>
          </div>
          <div className="navigation__list">
            <p className="navigation__list-title">Betaalmethodes</p>
            <li className="navigation__list-item">
              <div className="navigation__list-svg">
                <svg
                  id="ideal-logo"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 306.1 269.8"
                >
                  <g id="Group_167" data-name="Group 167">
                    <path
                      id="Path_104"
                      data-name="Path 104"
                      d="M0,20V249.8a20.059,20.059,0,0,0,20,20H157.3c103.8,0,148.8-58.1,148.8-135.2C306.1,57.9,261.1,0,157.3,0H20A20.059,20.059,0,0,0,0,20Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_105"
                      data-name="Path 105"
                      d="M91.9,56.4V226.2h73.9c67.1,0,96.2-37.9,96.2-91.5,0-51.3-29.1-91.1-96.2-91.1H104.7A12.825,12.825,0,0,0,91.9,56.4Z"
                      fill="#c06"
                    />
                    <g id="Group_166" data-name="Group 166">
                      <g id="Group_165" data-name="Group 165">
                        <path
                          id="Path_106"
                          data-name="Path 106"
                          d="M157.3,251.5H37.9a19.2,19.2,0,0,1-19.2-19.2V37.6A19.2,19.2,0,0,1,37.9,18.4H157.3c113.3,0,130.2,72.9,130.2,116.3C287.5,210,241.2,251.5,157.3,251.5ZM37.9,24.8A12.761,12.761,0,0,0,25.1,37.6V232.3a12.761,12.761,0,0,0,12.8,12.8H157.3c79.8,0,123.8-39.2,123.8-110.4,0-95.6-77.6-109.9-123.8-109.9H37.9Z"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Group_168" data-name="Group 168">
                    <path
                      id="Path_107"
                      data-name="Path 107"
                      d="M117.9,111.8a21.92,21.92,0,0,1,7.3,1.2,16.409,16.409,0,0,1,5.9,3.7,20.458,20.458,0,0,1,3.9,6.2,25.783,25.783,0,0,1,1.4,8.8,30.114,30.114,0,0,1-1.1,8.2,19.146,19.146,0,0,1-3.4,6.5,16.517,16.517,0,0,1-5.7,4.3,20.158,20.158,0,0,1-8.1,1.6H100.6V111.7h17.3Zm-.6,33.1a12.558,12.558,0,0,0,3.8-.6,7.453,7.453,0,0,0,3.2-2.1,11.657,11.657,0,0,0,2.3-3.8,15.853,15.853,0,0,0,.9-5.7,23.165,23.165,0,0,0-.6-5.5,10.934,10.934,0,0,0-2-4.2,9.016,9.016,0,0,0-3.6-2.7,14.722,14.722,0,0,0-5.5-.9h-6.4V145h7.9Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_108"
                      data-name="Path 108"
                      d="M172.5,111.8v7.5H151.1V128h19.7v6.9H151.1v9.9H173v7.5H142.2V111.7h30.3Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_109"
                      data-name="Path 109"
                      d="M203.1,111.8l15.2,40.6H209l-3.1-9H190.7l-3.2,9h-9l15.3-40.6h9.3Zm.5,24.9-5.1-14.9h-.1l-5.3,14.9Z"
                      fill="#fff"
                    />
                    <path
                      id="Path_110"
                      data-name="Path 110"
                      d="M232.8,111.8v33.1h19.8v7.5H223.9V111.8h8.9Z"
                      fill="#fff"
                    />
                  </g>
                  <g id="Group_169" data-name="Group 169">
                    <circle
                      id="Ellipse_9"
                      data-name="Ellipse 9"
                      cx="18.7"
                      cy="18.7"
                      r="18.7"
                      transform="translate(39.8 113.4)"
                    />
                  </g>
                  <path
                    id="Path_111"
                    data-name="Path 111"
                    d="M72.6,226.2h0a28.274,28.274,0,0,1-28.3-28.3V175.8a14.19,14.19,0,0,1,14.2-14.2h0a14.19,14.19,0,0,1,14.2,14.2v50.4Z"
                  />
                </svg>
              </div>
            </li>
          </div>
        </div>
        <div className="footer__bar">
          <div className="footer__bar-inner container">
            <p className="footer__bar-copyright">
              © 2021 copyright - Qustom Prints
            </p>
            <div className="footer__bar-navigation">
              <Link
                to="/info/algemene-voorwaarden"
                className="footer__bar-link"
              >
                Algemene voorwaarden
              </Link>
              <Link to="/info/privacy-beleid" className="footer__bar-link">
                Privacy beleid
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
