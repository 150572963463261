import {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';

export default function useProduct() {
    const [product, setProduct] = useState();
    const [firstOption, setFirstOption] = useState([]);
    const [loading, setLoading] = useState(true);
    const { productLinkId } = useParams();

    /**
     * useEffect to get a specific category with the correct products.
     *
     */
    useEffect(() => {
        try {
            // Fetch user from api
            fetch(global.api+"products/"+productLinkId,  {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((product) => {
                    // If there is something in the response add the response to the state
                    if (product) {
                        setProduct(product.data);
                    }
                });
        } catch (err) {
            return err
        } finally {
            // Finally, set the loading state to false
            setLoading(false);
        }
    }, [productLinkId])

    // function getFirstOptionData(firstOption) {
    //     try {
    //         // Fetch user from api
    //         fetch(global.api+"products/"+id+"/choices/" + firstOption,  {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 'accept': 'application/json'
    //             }
    //         })
    //             .then((res) => res.json())
    //             .then((option) => {
    //                 // If there is something in the response add the response to the state
    //                 if (option) {
    //                     setFirstOption(option.data);
    //                 }
    //             });
    //     } catch (err) {
    //         return err
    //     } finally {
    //         // Finally, set the loading state to false
    //         setLoading(false);
    //     }
    // }

    return {
        product,
        firstOption,
        loading
    }
}

