import React, {useState, useEffect} from 'react';
// import {moment} from "moment-business-days";
import {Link, useHistory} from "react-router-dom";
import { Tab, TabList, Tabs } from 'react-tabs';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import useShippingAddress from '../../hooks/useShippingAddress';
import moment from 'moment';
import 'moment/locale/nl';
import SmallLoading from '../../loading/SmallLoading';

export function DeliveryDate(props) {
    const {nextTab, addActiveClass, deliveryDates, setDeliveryDate, deliveryDate, onButtonAddActiveClass} = props;
    const [selectedDate, setSelectedDate] = useState();
    const [value, setValue] = useState(null);

    useEffect(() => {
        
    }, []);

    function nextStep(nextTab) {
        onButtonAddActiveClass(nextTab, "deliverydate", 3);
        // addActiveClass(nextTab)
    }

    function handleDateClick(date) {
        setSelectedDate(date);
        setDeliveryDate(date);
    }
    
    return (
        <div className="deliverydate">
            <div className="deliverydate__heading">
                <h2 className="deliverydate__heading-title">Kies een bezorgdatum</h2>
                <p className="deliverydate__heading-subtitle">Wij leveren helaas niet op zondagen, maar op alle andere dagen leveren wij wel</p>
            </div>
            <div className="deliverydate__list">
                {deliveryDates ? 
                    deliveryDates.map((date) => {
                        return (
                            <div className={`${date == selectedDate ? "selectedDate" : "deselectedDate"} deliverydate__list-item`} onClick={() => handleDateClick(date)}>
                                <div className="item__heading">
                                    <span className="item__heading-title">Bezorgdatum:</span>
                                </div>
                                <div className="item__content">
                                    <i className="ri-calendar-2-line"></i>
                                    <div className="item__content-date">
                                        <p className="item__content-day">{moment(date).format('dddd')}</p>
                                        <p className="item__content-datemonth">{moment(date).format('D MMMM')}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                :
                    <SmallLoading/>
                }
            </div>
            <div className="shoppingcart__button">
                <div className="shoppingcart__button-btn btn btn--primary" onClick={() => nextStep(nextTab)}><span>Volgende stap<i class="ri-arrow-right-line"></i></span></div>
            </div>
        </div>
    );
}
