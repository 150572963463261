import React, {useState, useEffect, useRef} from 'react';
import {Link, useHistory} from "react-router-dom";
import useProductPriceCalculation from '../hooks/useProductPriceCalculation';
import useShoppingCart from '../hooks/useShoppingCart';
import SmallLoading from '../loading/SmallLoading';

export function ShoppingCartPopUp(props) {
    const {showShoppingCartPopUp = false, onOutSideClick = false} = props;
    const {products, removeFromCart} = useShoppingCart();
    const {getPrice, configurationPrice} = useProductPriceCalculation();
    const history = useHistory();

    const ref = useRef(null);

    useEffect(() => {
        // if (showShoppingCartPopUp === true) {
        //     products.map((product) => {
        //         getPrice(product.chosenchoises, product.product.id, product.key);
        //     })
        // }
        document.addEventListener("mousedown", onMouseDown);

        return () => document.removeEventListener("mousedown", onMouseDown);
    }, [showShoppingCartPopUp]);

    const onMouseDown = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            onOutSideClick(false);
        }
    };

    function handleClick(e) {
        onOutSideClick(false);
        history.replace("/shoppingcart")
    }

    function handleClickWithoutRedirect(e) {
        onOutSideClick(false);
    }

    if (!showShoppingCartPopUp) {
        return null;
    } else {
        return (
            <div>
                <div ref={ref} className="shoppingcartpopup">
                    <div className="shoppingcartpopup__inner">
                        <div className="shoppingcartpopup__heading">
                            <span className="shoppingcartpopup__heading-title">Winkelmandje</span>
                        </div>
                        {products && products.length ? 
                        <div>
                            <div className="shoppingcartpopup__cart">
                                {products.map((product) => {
                                    return (
                                        <div className="shoppingcartpopup__cart-item">
                                            {product ? 
                                                product.uploadedFile ? 
                                                    <img className="shoppingcartpopup__cart-image" src={product.uploadedFile}/>
                                                :
                                                    <img className="shoppingcartpopup__cart-image" src={`https://shop.qustomshop.workers.dev/${product.product.images[0].url}`}/>
                                                
                                                : <SmallLoading/>
                                            }
                                            {console.log(product)}
                                            <div className="shoppingcartpopup__cart-content">
                                                <p className="cart__content-title">{product.product.name}</p>
                                                <p className="cart__content-count">Aantal: <span>{product.amount}x</span></p>
                                                <p className="cart__content-price"><i class="ri-price-tag-3-line"></i> € {product.price.toFixed(2)}</p>
                                            </div>
                                            <div className="shoppingcartpopup__cart-remove" onClick={() => removeFromCart(product.key)}><i class="ri-delete-bin-5-line"></i></div>
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="shoppingcartpopup__buttons">
                                <div className="shoppingcartpopup__buttons-wrapper">
                                    <a onClick={handleClick} className="shoppingcartpopup__button-btn btn btn--primary">
                                        <span>Bestellen<i class="ri-arrow-right-line"></i></span>
                                    </a>
                                </div>
                                <div className="shoppingcartpopup__buttons-wrapper">
                                    <a onClick={handleClickWithoutRedirect} className="shoppingcartpopup__button-subbtn">
                                        <span>Verder met winkelen</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        :
                            <div className="shoppingcartpopup__empty">
                                <div className="shoppingcartpopup__empty-icon">
                                    <i class="ri-shopping-cart-2-line"></i>
                                </div>
                                <p className="shoppingcartpopup__empty-title">Uw winkelmandje is leeg</p>
                                <a onClick={handleClickWithoutRedirect} className="shoppingcartpopup__button-btn btn btn--primary">
                                    <span>Ontdek producten<i class="ri-arrow-right-line"></i></span>
                                </a>
                            </div>
                        }
                    </div>
                </div>
                <div className="shoppingcartpopup__overlay"></div>
            </div>
        )
    };
}
