import { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";

const CONTACT_DEFAULT_VALUES = {
  name: "",
  email: "",
  message: "",
};

export default function useContact() {
  const [values, setValues] = useState(CONTACT_DEFAULT_VALUES);
  const [errors, setErrors] = useState(null);
  const history = useHistory();
  let location = useLocation();

  /**
   * Function to handle the form values on change.
   *
   * @param e
   */
  function onChange(e) {
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  /**
   * Function to handle the submit when someone logs in.
   *
   * @param e
   * @returns {Promise<any>}
   */
  async function onSubmit(e) {
    e.preventDefault();
    const settings = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...values }),
    };
    try {
      const res = await (
        await fetch(global.api + "contactform", settings)
      ).json();

      if (res.errors) {
        setErrors(res.errors);
      }
      if (res.data.id) {
        history.push("/contactconfirmation");
      }
    } catch (errors) {
      console.log(errors);
    }
  }

  return {
    onChange,
    onSubmit,
    values,
    errors,
  };
}
