import React from 'react';

export default function Loading() {
    return (
        <div className="loading container">
            <div className="loading__inner">
                <div className="loading__inner-spinner">
                    <div className="loadingio-spinner-rolling-wtp2uby56tg">
                        <div className="ldio-5q6n2yu62z">
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
