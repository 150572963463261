import React, { useState } from "react";

export function ChoiceUploadLibraryOrientations(props) {
  const { selectedOrientation, handleSelectedOrientation } = props;

  return (
    <div className="choiceuploadpopup__categories">
      <div className="choiceuploadcategories__list">
        <div
          className={`${
            selectedOrientation == ""
              ? "selectedorientation"
              : "deselectedorientation"
          } choiceuploadcategories__list-item`}
          onClick={() => handleSelectedOrientation("")}
        >
          <span>Alle verhoudingen</span>
        </div>
        <div
          className={`${
            selectedOrientation == "landscape"
              ? "selectedorientation"
              : "deselectedorientation"
          } choiceuploadcategories__list-item`}
          onClick={() => handleSelectedOrientation("landscape")}
        >
          <span>Liggend</span>
        </div>
        <div
          className={`${
            selectedOrientation == "squarish"
              ? "selectedorientation"
              : "deselectedorientation"
          } choiceuploadcategories__list-item`}
          onClick={() => handleSelectedOrientation("squarish")}
        >
          <span>Vierkant</span>
        </div>
        <div
          className={`${
            selectedOrientation == "portrait"
              ? "selectedorientation"
              : "deselectedorientation"
          } choiceuploadcategories__list-item`}
          onClick={() => handleSelectedOrientation("portrait")}
        >
          <span>Staand</span>
        </div>
      </div>
    </div>
  );
}
