import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import { Tab, TabList, Tabs } from 'react-tabs';

export function Confirmation(props) {

    return (
        <div className="confirmation">
            Confirmation
            <div className="shoppingcart__button">
                <Link to="/orders" className="shoppingcart__button-btn btn btn--primary"><span>Bekijk orders<i class="ri-arrow-right-line"></i></span></Link>
            </div>
        </div>
    );
}
