import React from "react";
import { Link, useHistory } from "react-router-dom";
import useRegister from "../hooks/useRegister";

export function Register() {
  const { onSubmit, onChange, values } = useRegister();
  const history = useHistory();
  return (
    <div className="register container">
      <div className="register__wrapper">
        <div className="register__inner">
          <div className="register__wrapper-form">
            <form className="register__form" onSubmit={onSubmit}>
              <h1 className="register__form-title">Registeer</h1>
              <p className="register__form-subtitle">
                Etiam a elementum justo. Morbi dignissim turpis velit, eu
                vestibulum erat molestie quis.
              </p>
              <div className="register__form-inner">
                <div className="register__form-input">
                  <h2 className="register__form-inputtitle">Name</h2>
                  <input
                    className="auth-input"
                    placeholder="Name"
                    type="text"
                    onChange={onChange}
                    value={values.name}
                    name="name"
                  />
                </div>
                <div className="register__form-input">
                  <h2 className="register__form-inputtitle">Emailadress</h2>
                  <input
                    className="auth-input"
                    placeholder="Email"
                    type="text"
                    onChange={onChange}
                    value={values.email}
                    name="email"
                  />
                </div>
                <div className="register__form-input">
                  <h2 className="register__form-inputtitle">Wachtwoord</h2>
                  <input
                    className="auth-input"
                    placeholder="Password"
                    type="password"
                    onChange={onChange}
                    value={values.password}
                    name="password"
                  />
                </div>
                <div className="register__form-input">
                  <h2 className="register__form-inputtitle">
                    Bevestig wachtwoord
                  </h2>
                  <input
                    className="auth-input"
                    placeholder="Password"
                    type="password"
                    onChange={onChange}
                    value={values.passwordConfirmation}
                    name="passwordConfirmation"
                  />
                </div>
                <div className="register__form-submit">
                  <button
                    className="register__form-button btn btn--primary"
                    type="submit"
                  >
                    <span>
                      Registreer<i class="ri-arrow-right-line"></i>
                    </span>
                  </button>
                  <p className="register__form-account">
                    Al een account?{" "}
                    <Link to="/login" className="login__form-link">
                      Log hier in
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
          <div className="register__message">
            <h3 className="register__message-title">
              Waarom een account aanmaken?
            </h3>
            <p className="register__message-subtitle">
              Etiam a elementum justo. Morbi dignissim turpis velit, eu
              vestibulum erat molestie quis.
            </p>
            <div className="register__message-points">
              <h4 className="message__title">Voordelen:</h4>
              <ul className="message__list">
                <li className="message__list-item">
                  <span className="message__list-point">
                    <i class="ri-check-fill"></i> Bestellingen vaker bestellen
                    in één klik
                  </span>
                </li>
                <li className="message__list-item">
                  <span className="message__list-point">
                    <i class="ri-check-fill"></i> Sneller bestellen door
                    gepersonaliseerde instellingen
                  </span>
                </li>
                <li className="message__list-item">
                  <span className="message__list-point">
                    <i class="ri-check-fill"></i> Speciale aanbiedingen die
                    alleen voor jou zijn
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
