import React, {useRef, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import { Configurator } from '../configurator/Configurator';
import useProduct from '../hooks/useProduct';
import Loading from '../loading/Loading';
import { ProductInformation } from './ProductInformation';

export function Product() {    
    const {product, loading} = useProduct();
    const [activeImage, setActiveImage] = useState();
    const [activeTab, setActiveTab] = useState("configuration");
    const { id } = useParams();

    const empty = [];
    const myRef = useRef(null);
    const scrollRef = useRef(null);

    useEffect(() => {
        if (product?.images.length === 0) {
            setActiveImage(null);
        } else {

            setActiveImage(product?.images[0].url);
        }
    }, [product])

    function scrollToSectionRef(ref) {
        window.scrollTo(0, ref.current.offsetTop); 
    }

    function onClickImageHandler(imageUrl) {
        setActiveImage(imageUrl);
    }

    function addActiveClass(a, ref) {
        window.scrollTo(0, ref.current.offsetTop); 
        setActiveTab(a);
    }
    return (
        <div id="product" className="product container">
            {loading === false ? 
                <div className="product__inner">
                    <div className="product__image">
                        <div className="product__image-img" style={{backgroundImage: `url("https://shop.qustomshop.workers.dev/${activeImage}")`}}>
                        </div>
                        <div className="product__image-thumbnails">
                            {product?.images.slice(0,5).map((image) => {
                                return (
                                    <div className="product__image-thumbnail" onClick={() => onClickImageHandler(image.url)} style={{backgroundImage: `url("https://shop.qustomshop.workers.dev/${image.url}")`}}></div>
                                )
                            })}
                        </div>
                    </div>
                    <div className="product__content">
                        <div className="product__information">
                            <h1 className="product__information-title">{product?.name}</h1>
                            {product?.longDescription ? <div className="product__information-description" dangerouslySetInnerHTML={ {__html: product?.longDescription} }></div> : <div></div>}

                            <div className="product__content-button">
                                <a onClick={() => addActiveClass('configuration', scrollRef)}  className="product__button-btn btn btn--primary">
                                    <span>Nu samenstellen<i class="ri-arrow-right-line"></i></span>
                                </a>
                                <div onClick={() => addActiveClass('information', scrollRef)} className="product__button-subbtn">
                                    <span>Meer informatie</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            :
                <Loading/>
            }
            <div className="product__tabs-list">
                <div className={`${activeTab === 'configuration' ? "activeProductNavigationTab" : "inactiveProductNavigationTab"} product__tabs-item`}
                        onClick={() => addActiveClass('configuration', scrollRef)}>
                    <h2 className="product__tabs-item-title">Samenstellen</h2>
                </div>
                <div ref={scrollRef} className={`${activeTab === 'information' ? "activeProductNavigationTab" : "inactiveProductNavigationTab"}  product__tabs-item`}
                        onClick={() => addActiveClass('information', scrollRef)}>
                    <h2 className="product__tabs-item-title">Informatie</h2>
                </div>
            </div>
            {product ? 
                <div>
                    <div key="configuration" className={`${activeTab === 'configuration' ? "activeProductTab" : "inactiveProductTab"} product__tabs-panel`}>
                        <Configurator product={product} myRef={myRef} empty={empty} scrollToSectionRef={scrollToSectionRef} productId={product?.id} productFirstChoice={product?.firstChoice} id={id}/>
                    </div>
                    <div key="information" className={`${activeTab === 'information' ? "activeProductTab" : "inactiveProductTab"} product__tabs-panel`}>          
                        <ProductInformation product={product}/>
                    </div>
                </div>
            :
            <Loading/>
            }
        </div>
    );
}
