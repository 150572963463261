import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ShoppingCartPopUp } from "../cart/ShoppingCartPopUp.js";
import useShoppingCart from "../hooks/useShoppingCart.js";
import NavbarMobile from "./NavbarMobile.js";

export default function NavbarTop() {
  const {
    products,
    showShoppingCartPopUp,
    setShowShoppingPopUp,
  } = useShoppingCart();
  const [mobileNavigation, setMobileNavigation] = useState(false);

  const handleClick = () => setShowShoppingPopUp(!showShoppingCartPopUp);

  const onOutSideClick = () => setShowShoppingPopUp(false);

  function enableMobileNavigation() {
    setMobileNavigation(true);
  }

  function closeMobileNavigation() {
    setMobileNavigation(false);
  }

  return (
    <div className="navbar__container">
      <ShoppingCartPopUp
        showShoppingCartPopUp={showShoppingCartPopUp}
        onOutSideClick={onOutSideClick}
        setShowShoppingPopUp={setShowShoppingPopUp}
      />
      <nav id="site-header" className="site-header">
        <div className="site-header__wrapper container">
          <div className="hamburger-icon">
            {mobileNavigation ? (
              <button
                className="action action--close"
                aria-label="Menu sluiten"
                onClick={closeMobileNavigation}
              ></button>
            ) : (
              <button
                className="action action--open"
                aria-label="Menu openen"
                onClick={enableMobileNavigation}
              ></button>
            )}
            <div className="navTrigger">
              <i></i>
              <i></i>
              <i></i>
            </div>
          </div>
          {mobileNavigation ? (
            <NavbarMobile setMobileNavigation={setMobileNavigation} />
          ) : (
            <div></div>
          )}
          <div className="site-header__branding">
            <Link to="/">
              <div
                id="site-header__branding-logo"
                className="site-header__branding-logo"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1442.809"
                  height="283.124"
                  viewBox="0 0 1442.809 283.124"
                >
                  <path
                    id="Union_2"
                    data-name="Union 2"
                    d="M7196.3-914.159l-.554.375-89.68-133.136,23.364-15.807,74.191,110.139,75.6-51.1,15.763,23.416-98.413,66.514Zm-96.706-23.335a116.452,116.452,0,0,1-22-6.844,117.2,117.2,0,0,1-19.91-10.829,118.013,118.013,0,0,1-17.347-14.341,118.185,118.185,0,0,1-14.314-17.382,117.561,117.561,0,0,1-10.807-19.95,117.06,117.06,0,0,1-6.83-22.045,118.508,118.508,0,0,1-2.381-23.67,118.505,118.505,0,0,1,2.381-23.669,117.028,117.028,0,0,1,6.83-22.046,117.561,117.561,0,0,1,10.807-19.95,118.177,118.177,0,0,1,14.314-17.383,118.008,118.008,0,0,1,17.347-14.341,117.1,117.1,0,0,1,19.91-10.829,116.474,116.474,0,0,1,22-6.844,117.918,117.918,0,0,1,23.624-2.386,117.893,117.893,0,0,1,23.622,2.386,116.489,116.489,0,0,1,22,6.844,117.15,117.15,0,0,1,19.911,10.829A118.085,118.085,0,0,1,7206.1-1135.6a118.329,118.329,0,0,1,14.313,17.383,117.491,117.491,0,0,1,10.807,19.95,117.1,117.1,0,0,1,6.83,22.046,118.577,118.577,0,0,1,2.381,23.669,118.58,118.58,0,0,1-2.381,23.67,117.1,117.1,0,0,1-6.83,22.045,117.423,117.423,0,0,1-10.807,19.95,118.348,118.348,0,0,1-12.871,15.911l-15.414-20.5a91.36,91.36,0,0,0,22.9-60.5c0-50.405-40.926-91.413-91.231-91.413s-91.231,41.008-91.231,91.413a91.424,91.424,0,0,0,91.231,91.411,91.024,91.024,0,0,0,22.875-2.91l16.162,21.488a116.7,116.7,0,0,1-15.992,4.492,117.837,117.837,0,0,1-23.622,2.386A117.855,117.855,0,0,1,7099.589-937.494Z"
                    transform="translate(-7005.998 1196.878)"
                    fill="#b7a767"
                  />
                  <text
                    id="USToM"
                    transform="translate(263.809 194)"
                    fill="#b7a767"
                    fontSize="200"
                    fontFamily="Montserrat-SemiBold, Montserrat"
                    fontWeight="600"
                    letterSpacing="0.081em"
                  >
                    <tspan x="0" y="0">
                      ustom
                    </tspan>
                  </text>
                  <text
                    id="Prints"
                    transform="translate(1020.809 194)"
                    fill="#0e121e"
                    fontSize="120"
                    fontFamily="Montserrat-Bold, Montserrat"
                    fontWeight="700"
                    letterSpacing="0.081em"
                  >
                    <tspan x="0" y="0">
                      Prints
                    </tspan>
                  </text>
                </svg>
              </div>
            </Link>
          </div>
          <div className="site-header__actions">
            <ul className="actions__menu">
              {/* <li className="actions__menu-item">
                                <Link to="/account" className="actions__menu-link"><i
                                    className="ri-account-circle-line"></i></Link>
                            </li> */}
              <li className="actions__menu-item">
                <a onClick={handleClick} className="actions__menu-link">
                  <i className="ri-shopping-cart-2-line"></i>
                </a>
                <div className="actions__menu-shopcount">
                  <span className="actions__menu-count">{products.length}</span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}
