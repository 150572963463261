import React, { useEffect } from "react";
import { Link } from "react-router-dom";

export default function PageContactConfirmation() {
  return (
    <div id="confirmation" className="confirmation container">
      <div className="confirmation__message">
        <i className="ri-check-line"></i>
        <h1 className="confirmation__message-title">Bedank voor uw bericht</h1>
        <p className="confirmation__message-subtitle">
          Wij nemen zo snel mogelijk contact met u op.
        </p>
        <div className="confirmation__button">
          <Link to="/" className="confirmation__button-btn btn btn--primary">
            <span>
              Winkelen in de tussentijd?<i class="ri-arrow-right-line"></i>
            </span>
          </Link>
        </div>
      </div>
    </div>
  );
}
