import React, { useState } from "react";

export function ChoiceUploadLibrarySearch(props) {
  const { searchValues, handleOnChange } = props;

  return (
    <div className="choiceuploadpopup__search">
      <div className="choiceuploadpopup__search-wrapper">
        <i className="ri-search-2-line"></i>
        <input
          className="auth-input"
          placeholder="Zoekterm bij voorkeur in het engels voor meer resultaat"
          onChange={(e) => handleOnChange(e)}
          name="search"
          value={searchValues.name}
          type="text"
        ></input>
      </div>
    </div>
  );
}
