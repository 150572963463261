// import "./App.scss";
import React, { useEffect } from 'react';
import moment from "moment";
import { BrowserRouter, Switch, Route, Link, useLocation } from "react-router-dom";
import { ShoppingCartPopUp } from "./components/cart/ShoppingCartPopUp";
import Footer from "./components/footer/Footer";
import NavbarBottom from "./components/navbar/NavbarBottom";
import NavbarTop from "./components/navbar/NavbarTop";
import CartProvider from "./components/providers/CartProvider";
import UserProvider from "./components/providers/UserProvider";
import { createBrowserHistory } from 'history';

import Router from "./components/router/Router";
import ReactGA from 'react-ga';

global.shopId = "0";
global.api = "https://shop.qustomshop.workers.dev/v1/0/";
global.key = "PMAK-60073a303173bc00477f2cb5-49b7189749e0cbbccad981ea94788fddc5";



require("./App0.scss");

// // Initialize google analytics page view tracking
// history.listen(location => {
//   ReactGA.set({ page: location.pathname }); // Update the user's current page
//   ReactGA.pageview(location.pathname); // Record a pageview for the given page
// });

function App() {
  return (
    <UserProvider>
      <CartProvider>
        <BrowserRouter>
          <div className="app">
            <div className="navbar">
              <NavbarTop />
              <NavbarBottom />
            </div>
            <div className="main">
              <Router />
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </CartProvider>
    </UserProvider>
  );
}

export default App;
