import React, {
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../loading/Loading";
import SmallLoading from "../loading/SmallLoading";
import { useDropzone } from "react-dropzone";
import useProductPriceCalculation from "../hooks/useProductPriceCalculation";
import useShoppingCart from "../hooks/useShoppingCart";
import useProduct from "../hooks/useProduct";
import { ChoiceUploadPopup } from "./ChoiceUploadPopup";
import calculateAspectRatios from "calculate-aspect-ratio";
import { ChoiceUploadPreview } from "./ChoiceUploadPreview";
import { ChoiceUploadPreviewFull } from "./ChoiceUploadPreviewFull";

const STATUS_CODES = {
  READY: "Bestand is klaar",
  CONVERTING_PDF_TO_IMAGE_400: "Voorbeeld aanmaken",
  CONVERTING_PDF_TO_IMAGE_2000: "Voorbeeld aanmaken bijna klaar",
  CALC_RESOLUTION: "De resolutie berekenen",
  "Bestand wordt geroteerd": "Bestand wordt geroteerd",
};
export function ChoiceUpload(props, event) {
  const {
    number,
    choiceData,
    setChosenChoices,
    chosenChoices,
    setAvailableChoices,
    onSubmit,
    handleCustomFile,
    choiceNameId,
    availableChoices,
    selectedAccesoires,
  } = props;
  const [uploadedFile, setUploadedFile] = useState(null);
  const [uploadedFileInfo, setUploadedFileInfo] = useState(null);
  const [startUploading, setStartUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [selectedFile, setSelectedFile] = useState();
  const [originalFile, setOrginalFile] = useState(null);
  const [selectedPhotoStatus, setSelectedPhotoStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [longId, setLongId] = useState(null);
  const [documentId, setDocumentId] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [choosenNextChoiceId, setChoosenNextChoiceId] = useState();
  const [loadingUpload, setLoadingUpload] = useState(true);
  const [showChoiceUploadPreview, setShowChoiceUploadPreview] = useState(false);
  const [
    showChoiceUploadPreviewFull,
    setShowChoiceUploadPreviewFull,
  ] = useState(false);
  const [showChoiceUploadPopup, setShowChoiceUploadPopup] = useState(false);
  const [fileAspectRatio, setFileAspectRatio] = useState(null);

  const {
    getPrice,
    getPriceId,
    configurationPrice,
  } = useProductPriceCalculation();
  const { addToCart } = useShoppingCart();
  const { product } = useProduct();

  const onDrop = useCallback((acceptedFiles) => {
    setUploadedFileInfo(acceptedFiles[0].name);
    setSelectedFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    try {
      // Fetch user from api
      fetch(
        global.api +
          "uploader?w=" +
          availableChoices.Breedte * 10 +
          "&h=" +
          availableChoices.Hoogte * 10 +
          "&bleed=20",
        {
          headers: {
            "Content-Type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((res) => res.json())
        .then((response) => {
          setLongId(response.data.longId);
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoadingUpload(false);
    }
  }, []);

  useEffect(() => {
    getPriceId(chosenChoices, selectedAccesoires);
  }, [availableChoices]);

  function onFileChange(event) {
    const file = event.target.files;
    const fileSize = file[0].size;
    const fileName = file[0].name;
    var reader = new FileReader();
    var url = reader.readAsDataURL(event.target.files[0]);
    console.log(url);
    // setOrginalFile(URL.createObjectURL(event.target.files[0]));
    // setOrginalFile(event.target.files[0]);
    setUploadedFileInfo(fileName);
    setSelectedFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    setStartUploading(true);
    const data = uploadFile();
  }
  function handleClickSubmit(event) {
    event.preventDefault();
    const filtered = chosenChoices.filter(
      (choice) => choice.choice !== event.target.id
    );
    const newState = [
      ...filtered,
      {
        name: "Bestand aanleveren",
        option: "Bestand aanleveren",
        choice: "Bestand aanleveren",
        value: uploadedFile,
      },
    ];

    setChosenChoices(newState);

    handleCustomFile(uploadedFile);
    setClicked(true);
    onSubmit(choiceData?.next.id, clicked);
  }

  async function uploadFile(params) {
    var reader = new FileReader();
    setOrginalFile(reader.readAsDataURL(selectedFile));
    const formData = new FormData();
    formData.append("file", selectedFile);
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
      body: formData,
    };
    try {
      const fetchResponse = await fetch(
        "https://print.conneo.io/backend/newfileParts?LongID=" + longId,
        settings
      ); // moet nog vervangen door eigen backend
      const data = await fetchResponse.json();
      if (data) {
        setTimeout(() => {
          setErrorMessage(null);
          getAssets();
        }, 1000);
      } else {
        setUploadedFile(null);
        setUploadedFileInfo(null);
        setStatus(null);
        setErrorMessage("Er is iets fout gegaan, probeer het opnieuw");
        setClicked(false);
        setStartUploading(false);
        setSelectedFile(null);
        setDocumentId(null);
      }
    } catch (e) {
      return e;
    }
  }

  async function uploadPhoto(url, fileName) {
    const data = { url: url, filename: fileName };
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
      body: JSON.stringify(data),
    };
    try {
      const fetchResponse = await fetch(
        "https://print.conneo.io/backend/newFileFromUrl?LongID=" + longId,
        settings
      ); // moet nog vervangen door eigen backend
      const data = await fetchResponse.json();
      if (data) {
        setTimeout(() => {
          setErrorMessage(null);
          getAssets();
        }, 1000);
      } else {
        setUploadedFile(null);
        setUploadedFileInfo(null);
        setStatus(null);
        setErrorMessage("Er is iets fout gegaan, probeer het opnieuw");
        setClicked(false);
        setStartUploading(false);
        setSelectedFile(null);
        setDocumentId(null);
      }
    } catch (e) {
      return e;
    }
  }

  async function croppedPhoto(croppedInfo) {
    const data = {
      stretchMode: "custom",
      cropInfo: { percentage: croppedInfo },
    };
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
      body: JSON.stringify(data),
    };
    try {
      const fetchResponse = await fetch(
        "https://print.conneo.io/backend/processDocument?LongID=" +
          longId +
          "&DocumentId=" +
          documentId +
          "&Page=1",
        settings
      );
      const data = await fetchResponse.json();

      if (data.status == "success") {
        setTimeout(() => {
          setErrorMessage(null);
          setUploadedFile(null);
          getAssets();
        }, 1000);
      } else {
        setUploadedFile(null);
        setUploadedFileInfo(null);
        setStatus(null);
        setErrorMessage("Er is iets fout gegaan, probeer het opnieuw");
        setClicked(false);
        setStartUploading(false);
        setSelectedFile(null);
        setDocumentId(null);
      }
    } catch (e) {
      return e;
    }
  }

  async function getAssets(params) {
    try {
      // Fetch user from api
      fetch(global.api + "uploader/assets?LongID=" + longId, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          // als getAssets nog geen conversionStatus = READY geeft: setTimeout getAssets
          // anders setTimeout getDocuments (http://localhost:8787/v1/0/uploader/documents?LongID=)
          if (
            response.data.assets != undefined &&
            response.data.assets.length > 0
          ) {
            const asset = response.data.assets[0];
            if (asset.conversionStatus == "READY") {
              return setTimeout(() => {
                setStatus(response.data.assets[0].conversionStatus);
                getDocuments();
              }, 1000);
            }
          }

          setStatus(response.data.assets[0].conversionStatus);
          setTimeout(() => {
            getAssets();
          }, 1000);
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoadingUpload(false);
    }
  }
  async function getDocuments(params) {
    try {
      // Fetch user from api
      fetch(global.api + "uploader/documents?LongID=" + longId, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.data.documents.length < 1) {
            setTimeout(() => {
              getDocuments();
            }, 1000);
          }
          if (response.data.documents.length > 0) {
            // documents opvragen
            setUploadedFile(response.data.documents[0].preview);
            setOrginalFile(response.data.documents[0].originalFile);
            setDocumentId(response.data.documents[0].documentid);
          }
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoadingUpload(false);
    }
  }

  async function getDocumentsRotate(params) {
    try {
      // Fetch user from api
      fetch(global.api + "uploader/documents?LongID=" + longId, {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((response) => {
          // documents opvragen
          if (response.data.documents.length > 0) {
            setStatus("Bestand wordt geroteerd");
            setUploadedFile(response.data.documents[0].preview);
            setDocumentId(response.data.documents[0].documentid);
            clearTimeout();
          } else {
            setTimeout(() => {
              setStatus("Bestand wordt geroteerd");
              getDocumentsRotate();
            }, 1000);
          }
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoadingUpload(false);
    }
  }

  async function rotateLeft(params) {
    setStatus("Bestand wordt geroteerd");
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
    };
    try {
      const fetchResponse = await fetch(
        global.api + "uploader/" + longId + "/" + documentId + "/rotate/left",
        settings
      );
      const data = await fetchResponse.json();
      if (data.status === "ok") {
        setUploadedFile(undefined);
        setUploadedFileInfo(undefined);
        setStatus("Bestand wordt geroteerd");
        setStartUploading(true);

        setTimeout(() => {
          getDocumentsRotate();
        }, 1000);
      }
    } catch (e) {
      return e;
    }
  }

  async function rotateRight(params) {
    setStatus("Bestand wordt geroteerd");
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
    };
    try {
      const fetchResponse = await fetch(
        global.api + "uploader/" + longId + "/" + documentId + "/rotate/right",
        settings
      );
      const data = await fetchResponse.json();
      if (data.status === "ok") {
        setUploadedFile(undefined);
        setUploadedFileInfo(undefined);
        setStatus("Bestand wordt geroteerd");
        setStartUploading(true);

        setTimeout(() => {
          getDocumentsRotate();
        }, 1000);
      }
    } catch (e) {
      return e;
    }
  }

  async function deleteFile(params) {
    // const formData = new FormData();
    // formData.append('file', selectedFile);
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json, text/javascript, */*; q=0.01",
      },
    };
    try {
      const fetchResponse = await fetch(
        global.api + "uploader/" + longId + "/" + documentId + "/delete",
        settings
      );
      const data = await fetchResponse.json();
      if (data.status === "ok") {
        setUploadedFile(null);
        setUploadedFileInfo(null);
        setStatus(undefined);
        setClicked(false);
        setStartUploading(false);
        setSelectedFile(null);
        setDocumentId(null);
      }
    } catch (e) {
      return e;
    }
  }

  function openChoiceUploadPreview(params) {
    setShowChoiceUploadPreview(true);
  }

  function openChoiceUploadPreviewFull(params) {
    setShowChoiceUploadPreviewFull(true);
  }

  function openLibraryPopup(params) {
    setShowChoiceUploadPopup(true);
  }

  function handleSelectedPhoto(photo) {
    setSelectedPhotoStatus(true);
    setShowChoiceUploadPopup(false);
    setUploadedFileInfo(photo.printFilename);
    setSelectedFile(photo.printFilename);
    setStartUploading(true);
    uploadPhoto(photo.printUrl, photo.printFilename);
  }

  return (
    <div>
      <ChoiceUploadPreviewFull
        showChoiceUploadPreviewFull={showChoiceUploadPreviewFull}
        setShowChoiceUploadPreviewFull={setShowChoiceUploadPreviewFull}
        uploadedFile={uploadedFile}
      />
      <ChoiceUploadPreview
        showChoiceUploadPreview={showChoiceUploadPreview}
        setShowChoiceUploadPreview={setShowChoiceUploadPreview}
        uploadedFile={uploadedFile}
        originalFile={originalFile}
        width={availableChoices.Breedte}
        height={availableChoices.Hoogte}
        croppedPhoto={croppedPhoto}
      />
      <ChoiceUploadPopup
        showChoiceUploadPopup={showChoiceUploadPopup}
        setShowChoiceUploadPopup={setShowChoiceUploadPopup}
        handleSelectedPhoto={handleSelectedPhoto}
      />
      <div className="choiceupload">
        <div className="choice__heading">
          <div className="choice__heading-number">
            <span>{number}</span>
          </div>
          <span className="choice__heading-title">Uploader</span>
        </div>
        <div className="choiceupload__inner configurator__choice-card">
          <div className="choiceupload__form">
            <form
              className="choiceupload__form-inner"
              encType="multipart/form-data"
              onSubmit={handleSubmit}
            >
              {uploadedFile ? (
                <div className="choiceupload__form-img">
                  <div
                    className="choiceupload__form-image"
                    style={{ backgroundImage: `url(${uploadedFile})` }}
                  >
                    {/* <img className="choiceupload__form-img" src={uploadedFile}/> */}
                    <div
                      className="choiceupload__form-hover"
                      onClick={openChoiceUploadPreviewFull}
                    >
                      <i className="ri-zoom-in-line"></i>
                    </div>
                  </div>
                </div>
              ) : uploadedFileInfo === null ? (
                <div className="choiceupload__form-empty">
                  <div className="choiceupload__form-wrapper">
                    <div
                      className="choiceupload__form-library"
                      onClick={openLibraryPopup}
                    >
                      <i className="ri-book-3-line"></i>
                      <p className="choiceupload__form-text">
                        Kies uit onze bibliotheek of
                      </p>
                    </div>
                    <div className="root" {...getRootProps()}>
                      <i className="ri-upload-2-line"></i>
                      {isDragActive ? (
                        <p class="file-name">filename</p>
                      ) : (
                        <p className="choiceupload__form-text choiceupload__form-link">
                          Sleep of selecteer jouw bestand hier
                          {/* <label className="choiceupload__form-link">
                            kies een bestand
                          </label> */}
                        </p>
                      )}
                      <input
                        id="choiceupload__form-input"
                        className="choiceupload__form-input"
                        type="file"
                        onChange={onFileChange}
                        {...getInputProps()}
                      />
                    </div>
                  </div>
                </div>
              ) : startUploading ? (
                <div className="choiceupload__form-status">
                  <div className="choiceupload__form-wrapper">
                    <SmallLoading />
                    <p className="status__title">Status van uw bestand:</p>
                    {status === null ? (
                      <p className="status__content">
                        Bestand naar server uploaden
                      </p>
                    ) : (
                      <p className="status__content">{STATUS_CODES[status]}</p>
                    )}
                  </div>
                </div>
              ) : (
                <div className="choiceupload__form-full">
                  <div className="choiceupload__form-wrapper">
                    <i class="ri-file-line"></i>
                    <input
                      id="choiceupload__form-input"
                      className="choiceupload__form-input"
                      type="file"
                      onChange={onFileChange}
                    />
                    <p className="choiceupload__form-text">
                      Sleep jouw bestand hier in of{" "}
                      <label
                        className="choiceupload__form-link"
                        for="choiceupload__form-input"
                      >
                        kies een bestand
                      </label>
                    </p>
                    <p class="choiceupload__form-filename">
                      {uploadedFileInfo}
                    </p>
                    <button
                      className="choiceupload__form-btn btn btn--primary"
                      type="submit"
                    >
                      <span>
                        Upload <i class="ri-arrow-right-line"></i>
                      </span>
                    </button>
                  </div>
                </div>
              )}
            </form>
            {errorMessage ? (
              <div className="choiceupload__form-error">
                <p>{errorMessage}</p>
              </div>
            ) : undefined}
            {uploadedFile ? (
              <div className="choiceupload__form-actions">
                <div className="choiceupload__form-delete" onClick={deleteFile}>
                  <i className="ri-delete-bin-5-line"></i>
                </div>
                <div className="choiceupload__form-rotate" onClick={rotateLeft}>
                  <i className="ri-anticlockwise-line"></i>
                </div>
                <div
                  className="choiceupload__form-rotate"
                  onClick={rotateRight}
                >
                  <i className="ri-clockwise-line"></i>
                </div>
                <div
                  className="choiceupload__form-popuptrigger"
                  onClick={openChoiceUploadPreview}
                >
                  <i className="ri-crop-line"></i>
                </div>
              </div>
            ) : undefined}
          </div>
        </div>
        {uploadedFile ? (
          <Link
            onClick={() =>
              addToCart(
                product,
                availableChoices,
                chosenChoices,
                configurationPrice.data.price,
                longId,
                uploadedFile,
                selectedAccesoires
              )
            }
            className="choicesummary__link btn btn--primary configurator__choice-card"
          >
            <span>
              Toevoegen aan winkelmandje<i class="ri-arrow-right-line"></i>
            </span>
          </Link>
        ) : (
          <div></div>
        )}
      </div>
    </div>
  );
}
