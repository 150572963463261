import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import useShoppingCart from "../../hooks/useShoppingCart";
import useShoppingCartPrice from "../../hooks/useShoppingCartPrice";
import SmallLoading from "../../loading/SmallLoading";
import { ShoppingCart } from "../ShoppingCart";

export function Overview(props) {
  const {
    nextTab,
    addActiveClass,
    onButtonAddActiveClass,
    setProducts,
    setShoppingCartPrice,
    shoppingCartPrice,
    setDeliveryDates,
  } = props;
  const {
    products,
    removeFromCart,
    decrementAmount,
    incrementAmount,
  } = useShoppingCart();
  const [completed, setCompleted] = useState(false);
  const [showAllSpecifications, setShowAllSpecifications] = useState(false);

  const ref = useRef();

  var moment = require("moment-business-days");
  useEffect(() => {
    const map = products.map((product) => {
      const newState = {
        productId: product.product.id,
        options: product.chosenchoises,
      };
      return newState;
    });

    fetchData(map);
    setProducts({ products });

    moment.updateLocale("nl", {
      workingWeekdays: [1, 2, 3, 4, 5, 6],
    });
  }, [products]);

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => document.removeEventListener("mousedown", onMouseDown);
  }, []);

  const onMouseDown = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowAllSpecifications(false);
    }
  };

  async function fetchData(map) {
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({ order: {}, lines: map }),
    };
    try {
      const fetchResponse = await fetch(
        global.api + "checkout/checkcart",
        settings
      );
      const data = await fetchResponse.json();
      setShoppingCartPrice(data);
      if (data) {
        const now = new Date(data.data.lines[0].deliveryDate);
        let dates = [];

        for (let i = 1; i < 9; i++) {
          let nextDay = new Date(now);
          nextDay.setDate(new Date(now).getDate() + i);
          if (moment(nextDay).isBusinessDay()) {
            dates = [...dates, nextDay];
          }
        }
        setDeliveryDates(dates);
      }
      return data;
    } catch (e) {
      return e;
    }
  }

  function mapObject(object, callback) {
    return Object.keys(object)
      .slice(0, 3)
      .map(function (key) {
        return callback(key, object[key]);
      });
  }

  function allMapObject(object, callback) {
    return Object.keys(object)
      .slice(3)
      .map(function (key) {
        return callback(key, object[key]);
      });
  }

  function nextStep(nextTab) {
    onButtonAddActiveClass(nextTab, "overview", 1);
  }
  return (
    <div className="overview">
      {products && products.length ? (
        <div className="overview__list">
          <div className="overview__list-inner">
            <div className="overview__list-mobile">
              {products.map((product) => {
                return (
                  <div className="overview__list-item">
                    <div className="overview__list-product">
                      {product.uploadedFile ? (
                        <img
                          className="overview__list-image"
                          src={product.uploadedFile}
                        />
                      ) : (
                        (product.product.images.length = 0 ? (
                          <img
                            className="shoppingcartpopup__cart-image"
                            src={"../images/placeholder.png"}
                          />
                        ) : (
                          <img
                            className="overview__list-image"
                            src={`https://shop.qustomshop.workers.dev/${product.product.images[0].url}`}
                          />
                        ))
                      )}
                      <div className="overview__list-content">
                        <p className="list__content-title">
                          {product?.product.name}
                        </p>
                        {mapObject(product.choices, function (key, value) {
                          return (
                            <div className="overview__values">
                              <p className="overview__values-name">{key}: </p>
                              <p className="overview__values-value">{value}</p>
                            </div>
                          );
                        })}
                        <p className="list__content-specifications">
                          Meer product specificaties zien
                        </p>
                      </div>
                    </div>

                    <div className="overview__list-price">
                      <p className="list__content-price">
                        <i class="ri-price-tag-3-line"></i> €{" "}
                        {product.price.toFixed(2)}
                      </p>
                    </div>
                    <div className="overview__list-actions">
                      <div className="overview__list-count">
                        <i
                          onClick={() => decrementAmount(product.key)}
                          className="ri-subtract-fill"
                        ></i>
                        <p className="list__content-count">
                          <span>{product.amount}x</span>
                        </p>
                        <i
                          onClick={() => incrementAmount(product.key)}
                          className="ri-add-fill"
                        ></i>
                      </div>
                      <div
                        className="overview__list-remove"
                        onClick={() => removeFromCart(product?.key)}
                      >
                        <i class="ri-delete-bin-5-line"></i>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="overview__list-desktop">
              {products.map((product, index) => {
                return (
                  <div className="overview__list-item">
                    {product.uploadedFile ? (
                      <img
                        className="overview__list-image"
                        src={product.uploadedFile}
                      />
                    ) : (
                      (product.product.images.length = 0 ? (
                        <img
                          className="shoppingcartpopup__cart-image"
                          src={"../images/placeholder.png"}
                        />
                      ) : (
                        <img
                          className="overview__list-image"
                          src={`https://shop.qustomshop.workers.dev/${product.product.images[0].url}`}
                        />
                      ))
                    )}
                    <div className="overview__list-content">
                      <p className="list__content-title">
                        {product?.product.name}
                      </p>
                      {mapObject(product.choices, function (key, value) {
                        return (
                          <div className="overview__values">
                            <p className="overview__values-name">{key}: </p>
                            <p className="overview__values-value">{value}</p>
                          </div>
                        );
                      })}
                      {showAllSpecifications === index ? (
                        <div
                          className="list__content-allspecifications"
                          ref={ref}
                        >
                          {allMapObject(product.choices, function (key, value) {
                            return (
                              <div className="overview__values">
                                <p className="overview__values-name">{key}: </p>
                                <p className="overview__values-value">
                                  {value}
                                </p>
                              </div>
                            );
                          })}
                          {product.accesoires
                            ? product.accesoires.map((accesoire) => {
                                return (
                                  <div className="overview__values">
                                    <p className="overview__values-name">
                                      Accesoires:{" "}
                                    </p>
                                    <p className="overview__values-value">
                                      {accesoire.name}
                                    </p>
                                  </div>
                                );
                              })
                            : undefined}
                        </div>
                      ) : undefined}
                      <p
                        className="list__content-specifications"
                        onClick={() => setShowAllSpecifications(index)}
                      >
                        Meer product specificaties zien
                      </p>
                    </div>
                    <div className="overview__list-count">
                      <i
                        onClick={() => decrementAmount(product.key)}
                        className="ri-subtract-fill"
                      ></i>
                      <p className="list__content-count">
                        <span>{product.amount}x</span>
                      </p>
                      <i
                        onClick={() => incrementAmount(product.key)}
                        className="ri-add-fill"
                      ></i>
                    </div>
                    <div className="overview__list-price">
                      <p className="list__content-price">
                        <i class="ri-price-tag-3-line"></i> €{" "}
                        {product.price.toFixed(2)}
                      </p>
                    </div>
                    <div
                      className="overview__list-remove"
                      onClick={() => removeFromCart(product?.key)}
                    >
                      <i class="ri-delete-bin-5-line"></i>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="shoppingcart__button">
            <div className="shoppingcart__button-inner">
              <div
                className="shoppingcart__button-btn btn btn--primary"
                onClick={() => nextStep(nextTab)}
              >
                <span>
                  Volgende stap<i class="ri-arrow-right-line"></i>
                </span>
              </div>
              <p className="shoppingcart__button-totalprice">
                Totale prijs:
                {shoppingCartPrice?.data.order.price ? (
                  <span>
                    € {shoppingCartPrice?.data?.order?.price.toFixed(2)}
                  </span>
                ) : (
                  <SmallLoading />
                )}
              </p>
            </div>
            <div />
          </div>
        </div>
      ) : (
        <div className="overview__empty">
          <i class="ri-shopping-cart-2-line"></i>
          <h3 className="overview__empty-title">Uw winkelmandje is leeg</h3>
          <p className="overview__empty-subtitle">
            Het ziet er naar uit dat u nog geen producten hebt toegevoegd aan uw
            winkelmandje. Klik op de knop hieronder om te beginnen.
          </p>
        </div>
      )}
    </div>
  );
}
