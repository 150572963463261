import React from "react";
import { Link } from "react-router-dom";
import BusinessPoints from "../businesspoints/BusinessPoints";
import { CategoriesList } from "../categories/CategoriesList";
import Header from "../header/Header";

export default function HomePageHandler() {
  const Component = require("../home/Home" + global.shopId).default;

  return <Component />;
}
