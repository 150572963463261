import React, { useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import useShippingAddress from "../../hooks/useShippingAddress";

export function DeliveryAddress(props) {
  const {
    nextTab,
    addActiveClass,
    onButtonAddActiveClass,
    values,
    secondValues,
    onChange,
    errors,
    secondErrors,
    handleCheck,
    handleSecondCheck,
    onSecondChange,
    onSubmit,
    formChanged,
    formChangedSecond,
    secondValuesDefault,
    setSecondValues,
    setFormChangedSecond,
    setOtherAddress,
    otherAddress,
    setValues,
  } = props;
  const [completed, setCompleted] = useState(false);
  const secondDeliveryAddressRef = useRef(null);

  useEffect(() => {
    const storageOrder = localStorage.getItem("order");
    const order = JSON.parse(storageOrder);
    if (order) {
      setValues(order.order.deliveryAddress);
      setSecondValues(order.order.invoiceAddress);
    }
  }, [localStorage.getItem("order")]);

  useEffect(() => {
    if (values.zipCode && values.houseNumber) {
      handleCheck();
    }
  }, [values.zipCode, values.houseNumber, values.houseNumberExt]);

  useEffect(() => {
    if (secondValues.zipCode && secondValues.houseNumber) {
      handleSecondCheck();
    }
  }, [
    secondValues.zipCode,
    secondValues.houseNumber,
    secondValues.houseNumberExt,
  ]);

  function nextStep(nextTab) {
    onButtonAddActiveClass(nextTab, "deliveryaddress", 2);
  }

  function enableOtherAddressActive() {
    setTimeout(() => {
      document.getElementById(`deliveryaddress__second`) &&
        document.getElementById(`deliveryaddress__second`).scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
    }, 10);
    setOtherAddress(true);
  }
  function disableOtherAddressActive() {
    setSecondValues(secondValuesDefault);
    setOtherAddress(false);
  }

  function handleSubmit(event) {
    event.preventDefault();
    onSubmit(otherAddress);
    nextStep("deliverydate", 2);
  }

  function addressCheck(event) {
    event.preventDefault();
    handleCheck(event);
  }

  function addressCheckSecond(event) {
    handleSecondCheck(event);
  }

  return (
    <div className="deliveryaddress">
      <form onSubmit={handleSubmit}>
        <div className="deliveryaddress__wrapper">
          <div className="deliveryaddress__information">
            <div className="deliveryaddress__inner">
              <h3 className="deliveryaddress__title">Bezorgadres toevoegen</h3>
              <p className="deliveryaddress__form-subtitle">
                Bestelling kunt u laten bezorgen op uw eigen adres of een
                afwijkend bezorgadres:
              </p>
              <h4 className="deliveryaddress__subtitle">
                Bezorgadres gegevens
              </h4>
              <div className="deliveryaddress__form">
                <div className="deliveryaddress__form-inner">
                  <div className="deliveryaddress__form-group">
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Volledige naam
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Volledige naam"
                        type="text"
                        value={values.name}
                        onChange={onChange}
                        name="name"
                        required
                      />
                    </div>
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Emailadres
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Emailadres"
                        type="text"
                        value={values.emailadres}
                        onChange={onChange}
                        name="emailadres"
                        required
                      />
                    </div>
                  </div>
                  <div className="deliveryaddress__form-group">
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Postcode
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Postcode"
                        type="text"
                        value={values.zipCode}
                        onChange={onChange}
                        name="zipCode"
                        required
                      />
                    </div>
                    <div className="deliveryaddress__form-input deliveryaddress__form-small">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Huisnummer
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Nr."
                        type="text"
                        value={values.houseNumber}
                        onChange={onChange}
                        name="houseNumber"
                        required
                      />
                    </div>
                    <div className="deliveryaddress__form-input deliveryaddress__form-small">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Toevoeging
                      </h2>
                      <input
                        className="auth-input"
                        type="text"
                        value={values.houseNumberExt}
                        onChange={onChange}
                        name="houseNumberExt"
                      />
                    </div>
                  </div>
                  <div className="deliveryaddress__form-group">
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Straatnaam
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Straat"
                        type="text"
                        value={values.street}
                        onChange={onChange}
                        name="street"
                        required
                      />
                    </div>
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Plaats
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Plaats"
                        type="text"
                        value={values.city}
                        onChange={onChange}
                        name="city"
                        required
                      />
                    </div>
                    <div className="deliveryaddress__form-input">
                      <h2 className="deliveryaddress__form-inputtitle">Land</h2>
                      <input
                        className="auth-input"
                        placeholder="Land"
                        type="text"
                        value={values.country}
                        onChange={onChange}
                        name="country"
                        required
                      />
                    </div>
                  </div>
                  <div className="deliveryaddress__form-group">
                    <div className="deliveryaddress__form-input deliveryaddress__form-phone">
                      <h2 className="deliveryaddress__form-inputtitle">
                        Telefoonnummer (optioneel)
                      </h2>
                      <input
                        className="auth-input"
                        placeholder="Telefoonnummer"
                        type="text"
                        value={values.telefoonnummer}
                        onChange={onChange}
                        name="phoneNumber"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="deliveryaddress__different">
              {/* <h4 className="deliveryaddress__different-title">Afwijkend bezorgadres toevoegen</h4>
                            <p className="deliveryaddress__different-subtitle">Voeg d.m.v. de knop hieronder een afwijkend adres toe, dit adres wordt dan het factuuradres</p> */}
              {otherAddress ? (
                <div
                  className="deliveryaddress__different-btn btn btn--primary"
                  onClick={disableOtherAddressActive}
                >
                  Afwijkend bezorgadres verwijderen
                </div>
              ) : (
                <div
                  className="deliveryaddress__different-btn btn btn--primary"
                  onClick={enableOtherAddressActive}
                >
                  Afwijkend bezorgadres toevoegen
                </div>
              )}
            </div>
            <div
              className="deliveryaddress__second"
              ref={secondDeliveryAddressRef}
              id="deliveryaddress__second"
            >
              {otherAddress ? (
                <div className="deliveryaddress__inner">
                  <h3 className="deliveryaddress__title">
                    Afwijkend bezorgadres toevoegen
                  </h3>
                  <div className="deliveryaddress__form">
                    <div className="deliveryaddress__form-inner">
                      <div className="deliveryaddress__form-group">
                        <div className="deliveryaddress__form-input deliveryaddress__form-medium">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Volledige naam
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Volledige naam"
                            type="text"
                            value={secondValues.name}
                            onChange={onSecondChange}
                            name="name"
                            required
                          />
                        </div>
                      </div>
                      <div className="deliveryaddress__form-group">
                        <div className="deliveryaddress__form-input">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Postcode
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Postcode"
                            type="text"
                            value={secondValues.zipCode}
                            onChange={onSecondChange}
                            name="zipCode"
                            required
                          />
                        </div>
                        <div className="deliveryaddress__form-input deliveryaddress__form-small">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Huisnummer
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Nr."
                            type="text"
                            value={secondValues.houseNumber}
                            onChange={onSecondChange}
                            name="houseNumber"
                            required
                          />
                        </div>
                        <div className="deliveryaddress__form-input deliveryaddress__form-small">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Toevoeging
                          </h2>
                          <input
                            className="auth-input"
                            type="text"
                            value={secondValues.houseNumberExt}
                            onChange={onSecondChange}
                            name="houseNumberExt"
                          />
                        </div>
                      </div>

                      <div className="deliveryaddress__form-group">
                        <div className="deliveryaddress__form-input">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Straatnaam
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Straat"
                            type="text"
                            value={secondValues.street}
                            onChange={onSecondChange}
                            name="street"
                            required
                          />
                        </div>
                        <div className="deliveryaddress__form-input">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Plaats
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Plaats"
                            type="text"
                            value={secondValues.city}
                            onChange={onSecondChange}
                            name="city"
                            required
                          />
                        </div>
                        <div className="deliveryaddress__form-input">
                          <h2 className="deliveryaddress__form-inputtitle">
                            Land
                          </h2>
                          <input
                            className="auth-input"
                            placeholder="Land"
                            type="text"
                            value={secondValues.country}
                            onChange={onSecondChange}
                            name="country"
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : undefined}
            </div>
          </div>
          <div className="deliveryaddress__summary">
            <div className="deliveryaddress__summary-content">
              <div className="deliveryaddress__summary-delivery">
                <h3 className="deliveryaddress__summary-title">
                  Bezorgadres samenvatting
                </h3>
                {errors ? (
                  <p className="deliveryaddress__summary-error">
                    Adres validatie niet mogelijk
                  </p>
                ) : undefined}
                {!formChanged == "" ? (
                  <div className="deliveryaddress__summary-inner">
                    <p className="deliveryaddress__summary-item">
                      {values.emailadres}
                    </p>
                    <p className="deliveryaddress__summary-item">
                      {values.phoneNumber}
                    </p>
                    <p className="deliveryaddress__summary-item">
                      {values.name}
                    </p>
                    <p className="deliveryaddress__summary-item">
                      {values.street} {values.houseNumber}
                      {values.houseNumberExt}
                    </p>
                    <p className="deliveryaddress__summary-item">
                      {values.zipCode} {values.city}
                    </p>
                    <p className="deliveryaddress__summary-item">
                      {values.country}
                    </p>
                  </div>
                ) : (
                  <p>Voeg een adres hiernaast toe</p>
                )}
              </div>
              {otherAddress ? (
                !formChangedSecond == "" ? (
                  secondErrors === false ? (
                    <div className="deliveryaddress__summary-invoice">
                      <h3 className="deliveryaddress__summary-title">
                        Afwijkend bezorgadres samenvatting
                      </h3>
                      <div className="deliveryaddress__summary-inner">
                        <p className="deliveryaddress__summary-item">
                          {secondValues.name}
                        </p>
                        <p className="deliveryaddress__summary-item">
                          {secondValues.street} {secondValues.houseNumber}
                          {secondValues.houseNumberExt}
                        </p>
                        <p className="deliveryaddress__summary-item">
                          {secondValues.zipCode} {secondValues.city}
                        </p>
                        <p className="deliveryaddress__summary-item">
                          {secondValues.country}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p>
                      De combinatie is niet juist, wij konden geen adres vinden
                    </p>
                  )
                ) : (
                  <div>
                    <h3 className="deliveryaddress__summary-title">
                      Afwijkend bezorgadres samenvatting
                    </h3>
                    <p>Voeg een adres hiernaast toe</p>
                  </div>
                )
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
        <div className="shoppingcart__button">
          <button
            className="shoppingcart__button-btn btn btn--primary"
            type="submit"
          >
            <span>
              Volgende stap<i class="ri-arrow-right-line"></i>
            </span>
          </button>
        </div>
      </form>
    </div>
  );
}
