import React, { useState } from "react";
import useShippingAddress from "../hooks/useShippingAddress";
import useShoppingCart from "../hooks/useShoppingCart";
import { Confirmation } from "./tabs/Confirmation";
import { DeliveryAddress } from "./tabs/DeliveryAddress";
import { DeliveryDate } from "./tabs/DeliveryDate";
import { Overview } from "./tabs/Overview";
import { OverviewNew } from "./tabs/OverviewNew";
import { Payment } from "./tabs/Payment";
import { Summary } from "./tabs/Summary";

const COMPLETED_DEFAULTS = {
  overview: false,
  deliveryaddress: false,
  deliverydate: false,
};

export function ShoppingCart() {
  const [activeTab, setActiveTab] = useState("overview");
  const [activeTabKey, setActiveTabKey] = useState(0);
  const [completed, setCompleted] = useState(COMPLETED_DEFAULTS);
  const { productsTest } = useShoppingCart();
  const [products, setProducts] = useState();
  const [otherAddress, setOtherAddress] = useState(false);
  const [shoppingCartPrice, setShoppingCartPrice] = useState();
  const [deliveryDates, setDeliveryDates] = useState();
  const [deliveryDate, setDeliveryDate] = useState();
  const [order, setOrder] = useState({ orderheader: [], orderregels: [] });
  const {
    onChange,
    onSubmit,
    values,
    secondValues,
    onSecondChange,
    handleCheck,
    handleSecondCheck,
    errors,
    secondErrors,
    allValues,
    formChanged,
    formChangedSecond,
    SECONDSHIPPINGADDRESS_DEFAULT_VALUE,
    setSecondValues,
    setFormChangedSecond,
    setValues,
  } = useShippingAddress();

  function addActiveClass(a, name, key) {
    setActiveTabKey(key);
    setActiveTab(a);
  }

  function onButtonAddActiveClass(a, name, key) {
    setActiveTabKey(key);
    setCompleted((v) => ({ ...v, [name]: true }));
    setActiveTab(a);
  }

  return (
    <div className="shoppingcart container">
      <div className="shoppingcart__tabs">
        <div className="shoppingcart__tabs-navigation">
          <div className="shoppingcart__tabs-list">
            <div
              key={0}
              className={`${
                activeTab === "overview"
                  ? "activeNavigationTab"
                  : "inactiveNavigationTab"
              } ${completed.overview === true ? "completed" : "incompleted"} ${
                0 < activeTabKey ? "actived" : "deactived"
              } shoppingcart__tabs-item`}
              onClick={
                0 < activeTabKey
                  ? () => addActiveClass("overview", "deliveryaddress", 0)
                  : undefined
              }
            >
              <i class="ri-eye-2-line"></i>
              <p>Overzicht</p>
            </div>
            <div
              key={1}
              className={`${
                activeTab === "deliveryaddress"
                  ? "activeNavigationTab"
                  : "inactiveNavigationTab"
              } ${
                completed.deliveryaddress === true ? "completed" : "incompleted"
              } ${
                1 < activeTabKey ? "actived" : "deactived"
              } shoppingcart__tabs-item`}
              onClick={
                1 < activeTabKey
                  ? () => addActiveClass("deliveryaddress", "deliverydate", 1)
                  : undefined
              }
            >
              <i class="ri-truck-line"></i>
              <p>Bezorgadres</p>
            </div>
            <div
              key={2}
              className={`${
                activeTab === "deliverydate"
                  ? "activeNavigationTab"
                  : "inactiveNavigationTab"
              } ${
                completed.deliverydate === true ? "completed" : "incompleted"
              } ${
                2 < activeTabKey ? "actived" : "deactived"
              } shoppingcart__tabs-item`}
              onClick={
                2 < activeTabKey
                  ? () => addActiveClass("deliverydate", "summary", 2)
                  : undefined
              }
            >
              <i class="ri-calendar-2-line"></i>
              <p>Bezorgdatum</p>
            </div>
            <div
              key={3}
              className={`${
                activeTab === "summary"
                  ? "activeNavigationTab"
                  : "inactiveNavigationTab"
              } ${
                completed.summary === true ? "completed" : "incompleted"
              } shoppingcart__tabs-item`}
              onClick={() => addActiveClass("summary", "payment", 3)}
            >
              <i class="ri-survey-line"></i>
              <p>Samenvatting</p>
            </div>
            {/* <div className={`${activeTab === 'payment' ? "activeNavigationTab" : "inactiveNavigationTab"} ${completed.payment === true ? "completed" : "incompleted"} shoppingcart__tabs-item`}
                             onClick={() => addActiveClass('payment', 'confirmation')}>
                            <i class="ri-bank-card-line"></i>
                            <p>Betaling</p>
                        </div>
                        <div className={`${activeTab === 'confirmation' ? "activeNavigationTab" : "inactiveNavigationTab"} shoppingcart__tabs-item`}
                             onClick={() => addActiveClass('confirmation', '')}>
                            <i class="ri-check-fill"></i>
                            <p>Bevestiging</p>
                        </div> */}
          </div>
        </div>
        <div className="shoppingcart__tabs-panels">
          <div
            key="overview"
            className={`${
              activeTab === "overview" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <Overview
              setDeliveryDates={setDeliveryDates}
              deliveryDates={deliveryDates}
              setShoppingCartPrice={setShoppingCartPrice}
              shoppingCartPrice={shoppingCartPrice}
              setProducts={setProducts}
              nextTab={"deliveryaddress"}
              addActiveClass={addActiveClass}
              onButtonAddActiveClass={onButtonAddActiveClass}
            />
          </div>
          <div
            key="deliveryaddress"
            className={`${
              activeTab === "deliveryaddress" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <DeliveryAddress
              setOtherAddress={setOtherAddress}
              otherAddress={otherAddress}
              setOrder={setOrder}
              nextTab={"deliverydate"}
              addActiveClass={addActiveClass}
              onButtonAddActiveClass={onButtonAddActiveClass}
              onChange={onChange}
              values={values}
              secondValues={secondValues}
              errors={errors}
              secondErrors={secondErrors}
              onSubmit={onSubmit}
              onSecondChange={onSecondChange}
              handleCheck={handleCheck}
              handleSecondCheck={handleSecondCheck}
              formChanged={formChanged}
              formChangedSecond={formChangedSecond}
              secondValuesDefault={SECONDSHIPPINGADDRESS_DEFAULT_VALUE}
              setSecondValues={setSecondValues}
              setValues={setValues}
            />
          </div>
          <div
            key="deliverydate"
            className={`${
              activeTab === "deliverydate" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <DeliveryDate
              deliveryDates={deliveryDates}
              setDeliveryDate={setDeliveryDate}
              shoppingCartPrice={shoppingCartPrice}
              nextTab={"summary"}
              addActiveClass={addActiveClass}
              onButtonAddActiveClass={onButtonAddActiveClass}
            />
          </div>
          <div
            key="summary"
            className={`${
              activeTab === "summary" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <Summary
              completed={completed}
              deliveryDate={deliveryDate}
              otherAddress={otherAddress}
              shoppingCartPrice={shoppingCartPrice}
              setProducts={setProducts}
              nextTab={"payment"}
              addActiveClass={addActiveClass}
              onButtonAddActiveClass={onButtonAddActiveClass}
              allValues={allValues}
              products={products}
            />
          </div>
          <div
            key="payment"
            className={`${
              activeTab === "payment" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <Payment
              nextTab={"confirmation"}
              addActiveClass={addActiveClass}
              onButtonAddActiveClass={onButtonAddActiveClass}
            />
          </div>
          <div
            key="confirmation"
            className={`${
              activeTab === "confirmation" ? "activeTab" : "inactiveTab"
            } shoppingcart__tabs-panel`}
          >
            <Confirmation />
          </div>
        </div>
      </div>
    </div>
  );
}
