import React, { useEffect, createContext, useState } from "react";
import Loading from "../loading/Loading";

export const UserContext = createContext({});

/**
 * User provider
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function UserProvider(props) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [isAuthed, setIsAuthed] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  /**
   * useEffect to get the current logged in user
   *
   */
  useEffect(() => {
    // First check if auth token is available in localstorage
    if (localStorage.getItem("token")) {
      // Fetch user from api
      fetch(global.api + "user/get", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          Accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((user) => {
          /**
           * If there is a user id in the response set the user and set the isAuthed state to true
           */
          if (user) {
            setUser(user.data.user);
            setIsAuthed(true);
          }

          /**
           * If the administrator column is 1 set the isAdmin state to true.
           */
          if (user.administrator) {
            setIsAdmin(true);
          }
        })
        .catch((err) => {
          setLoading(false);
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
  }, []);

  /**
   * Function to store a (new) user in Provider
   *
   */
  function storeLoggedinUser(user) {
    setUser(user);
    setIsAuthed(true);
  }

  /**
   * Function to remove the logged in user from the state and localstorage
   *
   */
  function removeLoggedinUser() {
    setUser(undefined);
    setIsAuthed(false);
    localStorage.removeItem("token");
  }

  if (loading) return <Loading />;

  return (
    <UserContext.Provider
      value={{
        user,
        isAuthed,
        storeLoggedinUser,
        removeLoggedinUser,
        setUser,
        loading,
        isAdmin,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
}
