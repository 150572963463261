import React from "react";
import { Link, useHistory } from "react-router-dom";
import useFavouriteProducts from "../hooks/useFavouriteProducts";
import { generateCategoryUrl } from "../helpers/Helpers";
import Loading from "../loading/Loading";

export function FavouriteProjectsList() {
  const { favourites, loading } = useFavouriteProducts();
  const history = useHistory();
  return (
    <div className="categories-list container">
      <div className="categories-list__heading">
        <h2 className="categories-list__heading-title">Favoriete producten</h2>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="categories-list__inner">
          {favourites?.products.slice(0, 5).map((product) => (
            <div
              className="categories-list__item categorie-item"
              onClick={() =>
                history.push(product.categoryId + "/" + product?.id)
              }
              key={product.id}
            >
              <div className="categorie-item__image">
                {!product.thumbnail ? (
                  <img
                    className="categorie-item__image-img"
                    src={"../images/category-placeholder.jpg"}
                  />
                ) : (
                  <div
                    className="categorie-item__image"
                    style={{
                      backgroundImage: `url("https://shop.qustomshop.workers.dev/${product.thumbnail}")`,
                    }}
                  />
                )}
              </div>
              <div className="categorie-item__content">
                <div className="categorie-item__heading">
                  <h3 className="categorie-item__heading-title">
                    {product.name}
                  </h3>
                  <div
                    className="categorie-item__heading-description"
                    dangerouslySetInnerHTML={{
                      __html: product.shortDescription,
                    }}
                  ></div>
                </div>
                <div className="categorie-item__button">
                  <div className="categorie-item__button-btn btn btn--primary">
                    <span>
                      Meer zien?<i className="ri-arrow-right-line"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
