import React, {useContext, useState, useMemo, useRef} from 'react';
import {Link, useHistory,} from "react-router-dom";
import Loading from '../loading/Loading';

export function ChoiceSizeOption(props, event) {
    const {number, choiceData, availableChoices, onClickSize, choiceNameId, choiceId, activeChoice, loading, onClickSizeBoth} = props;
    const [selectedOption, setSelectedOption] = useState();
    const [choosenNextChoiceId, setChoosenNextChoiceId] = useState();
    
    function handleClick(id, value, choiceId, nextId, valueId, optionName, valueHeight, valueWidth) {
        if (valueWidth && valueHeight) {
            const valueBoth = {Hoogte: valueHeight, Breedte: valueWidth}
            setSelectedOption(value);
            setChoosenNextChoiceId(nextId);
            onClickSizeBoth(id, valueBoth, choiceId, nextId, valueId, optionName, selectedOption, choosenNextChoiceId);
        } else {
            if (valueHeight) {
                const value = valueHeight;
                setSelectedOption(value);
                setChoosenNextChoiceId(nextId);
                onClickSize(id, value, choiceId, nextId, valueId, optionName, selectedOption, choosenNextChoiceId);
            }
            if (valueWidth) {
                const value = valueWidth;
                setSelectedOption(value);
                setChoosenNextChoiceId(nextId);
                onClickSize(id, value, choiceId, nextId, valueId, optionName, selectedOption, choosenNextChoiceId);
            }
        } 
    }

    return (
        <div className="choiceoption">
            <div className="choice__heading">
                <div className="choice__heading-number"><span>{number}</span></div>
                <span className="choice__heading-title">{choiceData?.name}: </span>
                <div className="choice__heading-result">
                    <p className="result__text">{selectedOption}</p>
                </div>
                <div className="choice__heading-closebutton">
                    {activeChoice === choiceData?.id ? <i class="ri-arrow-up-s-line"></i> : <i class="ri-arrow-down-s-line"></i>}
                </div>
            </div>
            {loading ? 
                <Loading/>
            :
                <form className="choiceoption__form configurator__choice-card ">
                    {choiceData.choices.map((choice) => {               
                        const id = choiceNameId;
                        const value = choice?.name;
                        const valueWidth = choice?.width;
                        const valueHeight = choice?.height;
                        const optionName = choiceData.name;
                        const valueId = choice?.id
                        const nextId = choice?.next.id;
                        
                        return (
                            <div className={`${value == selectedOption ? "selected" : "deselected"} choiceoption__heading`} onClick={() => handleClick(id, value, choiceId, nextId, valueId, optionName, valueHeight, valueWidth)} id={id} value={value}>
                                <img className="choiceoption__heading-img" src={"https://shop.qustomshop.workers.dev/"+choice.image.url}/>
                                <div className="choiceoption__heading-content">
                                    <h2 className="choiceoption__heading-title">{choice.name}</h2>
                                    <p className="choiceoption__heading-subtitle">{choice.description}</p>
                                </div>
                            </div>
                        )
                        
                    })}
                </form>
            }
        </div>
    );
}
