import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

const SHIPPINGADDRESS_DEFAULT_VALUE = {
  name: "",
  emailadres: "",
  telefoonummer: "",
  street: "",
  houseNumber: "",
  houseNumberExt: "",
  zipCode: "",
  city: "",
  country: "",
};

const SECONDSHIPPINGADDRESS_DEFAULT_VALUE = {
  name: "",
  street: "",
  houseNumber: "",
  houseNumberExt: "",
  zipCode: "",
  city: "",
  country: "",
};

export default function useShippingAddress() {
  const [values, setValues] = useState(SHIPPINGADDRESS_DEFAULT_VALUE);
  const [secondValues, setSecondValues] = useState(
    SECONDSHIPPINGADDRESS_DEFAULT_VALUE
  );
  const [allValues, setAllValues] = useState();
  const [errors, setErrors] = useState(false);
  const [secondErrors, setSecondErrors] = useState(false);
  const [formChanged, setFormChanged] = useState(false);
  const [formChangedSecond, setFormChangedSecond] = useState(false);
  const [loading, setLoading] = useState(true);

  async function getAddress() {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      // Make a get request to the backend with the next choiceID.
      const fetchResponse = await fetch(
        global.api +
          "checkout/zipcode?&postal_code=" +
          values.zipCode +
          "&street_number=" +
          values.houseNumber +
          "&street_number_suffix=" +
          values.houseNumberExt,
        settings
      );

      // Put the response in a const.
      const data = await fetchResponse.json();
      if (data.results) {
        setErrors(false);
        setValues({
          ...values,
          zipCode: data.results[0].postal_code,
          houseNumber: data.results[0].street_number,
          street: data.results[0].street_name,
          city: data.results[0].city,
          country: data.results[0].country.name,
        });
      } else {
        setErrors(true);
      }
    } catch (e) {
      setErrors(e);
    } finally {
      setLoading(false);
    }
  }

  async function getSecondAddress() {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      // Make a get request to the backend with the next choiceID.
      const fetchResponse = await fetch(
        global.api +
          "checkout/zipcode?&postal_code=" +
          secondValues.zipCode +
          "&street_number=" +
          secondValues.houseNumber +
          "&street_number_suffix=" +
          secondValues.houseNumberExt,
        settings
      );

      // Put the response in a const.
      const data = await fetchResponse.json();
      if (data.results) {
        setErrors(false);
        setSecondValues({
          ...secondValues,
          zipCode: data.results[0].postal_code,
          houseNumber: data.results[0].street_number,
          street: data.results[0].street_name,
          city: data.results[0].city,
          country: data.results[0].country.name,
        });
      } else {
        setSecondErrors(true);
      }
    } catch (e) {
      setSecondErrors(false);
    } finally {
      setLoading(false);
    }
  }

  function handleCheck() {
    getAddress();
  }

  function handleSecondCheck() {
    getSecondAddress();
  }

  /**
   * Function to handle the form values on change.
   *
   * @param e
   */
  function onChange(e) {
    setFormChanged(true);
    setValues({ ...values, [e.target.name]: e.target.value });
  }

  /**
   * Function to handle the form values on change.
   *
   * @param e
   */
  function onSecondChange(e) {
    setFormChangedSecond(true);
    setSecondValues({ ...secondValues, [e.target.name]: e.target.value });
  }

  /**
   * Function to handle the submit when someone logs in.
   *
   * @param e
   * @returns {Promise<any>}
   */
  function onSubmit(otherAddress) {
    if (otherAddress === true) {
      setAllValues({ invoiceAddress: values, deliveryAddress: secondValues });
    } else {
      setAllValues({ invoiceAddress: values, deliveryAddress: values });
    }
  }

  return {
    onChange,
    onSecondChange,
    onSubmit,
    values,
    setValues,
    secondValues,
    setSecondValues,
    handleCheck,
    handleSecondCheck,
    loading,
    errors,
    secondErrors,
    formChanged,
    formChangedSecond,
    setFormChangedSecond,
    SECONDSHIPPINGADDRESS_DEFAULT_VALUE,
    allValues,
  };
}
