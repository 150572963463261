import React, { useEffect, useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import useCategory from '../hooks/useCategory';
import {generateCategoryUrl, generateProductUrl} from '../helpers/Helpers';
import Loading from '../loading/Loading';

export function Category() {
    const {category, loading} = useCategory();
    const history = useHistory();

    return (
        <div className="categories-list container">
            <div className="categories-list__heading">
                <h2 className="categories-list__heading-title">Categorie: {category?.name}</h2>
            </div>
            {/* <div className="categories-list__navigation">
                {category?.subCategories.map((subCategory) => {
                    return (
                        <div className="categories-list__navigation-item">
                            <Link to={"/"+category.id+"/"+subCategory.name}>{subCategory.name}</Link>
                        </div>
                    )
                })}
            </div> */}
            {loading ? <Loading/> :
                <div className="categories-list__inner">
                    {category?.products?.map((product) =>
                        <div className="categories-list__item categorie-item"  onClick={() => history.push("/"+category?.id+"/"+product.id)} key={product.id}>
                            <div className="categorie-item__image" style={{backgroundImage: `url("https://shop.qustomshop.workers.dev/${product.thumbnail}")`}}>
                            </div>
                            <div className="categorie-item__content">
                                <div className="categorie-item__heading">
                                    <h3 className="categorie-item__heading-title">{product.name}</h3>
                                    <div className="categorie-item__heading-description" dangerouslySetInnerHTML={ {__html: product.shortDescription} }></div>
                                </div>
                                <div className="categorie-item__button">
                                    <div  onClick={() => history.push(generateProductUrl())} key={product.id} className="categorie-item__button-btn btn btn--primary">
                                        <span>Meer zien?<i class="ri-arrow-right-line"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            }
        </div>
    );
}
