import React from "react";
import { Link } from "react-router-dom";
import { FavouriteProjectsList } from "../favourites/FavouriteProjectsList";
import HeaderFullWidth from "../header/HeaderFullWidth";

export default function Home2() {
  return (
    <div className="home">
      <HeaderFullWidth />
      <FavouriteProjectsList />
    </div>
  );
}
