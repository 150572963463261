import React, { useEffect, useState } from "react";
import Loading from "../loading/Loading";

export default function useCategories(props) {
  const [favourites, setFavourites] = useState();
  const [loading, setLoading] = useState(true);

  /**
   * useEffect to get all favourite products and the data from each product.
   *
   */
  useEffect(() => {
    try {
      // Fetch user from api
      fetch(global.api + "categories/___favorite___", {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
          "Access-Control-Allow-Origin": "*",
          Origin: "https://shop.qustomshop.workers.dev",
        },
      })
        .then((res) => res.json())
        .then((favourites) => {
          // If there is something in the response add the response to the state
          if (favourites) {
            setFavourites(favourites.data);
          }
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoading(false);
    }
  }, []);

  return {
    favourites,
    loading,
  };
}
