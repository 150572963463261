import React, {useState} from 'react';
import { useParams } from 'react-router-dom';

export default function useCategory(props) {
    const [category, setCategory] = useState();
    const [loading, setLoading] = useState(true);
    const { categoryId } = useParams();
    
    /**
     * useEffect to get a specific category with the correct products.
     *
     */
    React.useEffect(() => {
        try {
            // Fetch user from api
            fetch(global.api+"categories/"+categoryId,  {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((category) => {
                    // If there is something in the response add the response to the state
                    if (category) {
                        setCategory(category.data);
                    }
                });
        } catch (err) {
            return err
        } finally {
            // Finally, set the loading state to false
            setLoading(false)
        }
    }, [categoryId])

    return {
        category,
        loading
    }
}

