import React from "react";
import { Link } from "react-router-dom";
import BusinessPoints from "../businesspoints/BusinessPoints";
import { CategoriesList } from "../categories/CategoriesList";
import { FavouriteProjectsList } from "../favourites/FavouriteProjectsList";
import Header from "../header/Header";

export default function Home0() {
  return (
    <div className="home">
      <Header />
      <BusinessPoints />
      <FavouriteProjectsList />
    </div>
  );
}
