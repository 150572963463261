import { Route, Switch, useLocation } from "react-router-dom";
import About from "../about/About";
import { Login } from "../auth/Login";
import { Register } from "../auth/Register";
import { ShoppingCart } from "../cart/ShoppingCart";
import { Category } from "../categories/Category";
import HomePageHandler from "../home/HomePageHandler";
import Confirmation from "../order/Confirmation";
import Page from "../page/Page";
import PageContact from "../page/PageContact";
import PageContactConfirmation from "../page/PageContactConfirmation";
import { Product } from "../products/Product";
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';


export default function Router() {

  const location = useLocation();
  ReactGA.initialize('G-2XCMZSCHK4');
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page

  return (
    <Switch>
      <Route exact path="/" component={HomePageHandler} />
      <Route path="/info/contact" component={PageContact} />
      <Route path="/info/:pageId" component={Page} />
      <Route path="/thanks" component={Confirmation} />
      <Route path="/contactconfirmation" component={PageContactConfirmation} />
      <Route path="/shoppingcart" component={ShoppingCart} />
      <Route path="/about" component={About} />
      <Route path="/login" component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/:categoryId/:productLinkId">
        <Product />
      </Route>
      <Route path="/:categoryId" component={Category} />
    </Switch>
  );
}
