import React from 'react';

export function ProductInformation(props) {
    const {product} = props;
    return (
        <div className="productinformation">
            <div className="productinformation__wrapper">
                <div className="productinformation__description" dangerouslySetInnerHTML={ {__html: product?.technicalDescription} }></div>
            </div>
        </div>
    )
}
