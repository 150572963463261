import React from 'react';
import { Link } from 'react-router-dom';

export default function About() {
    return (
        <div className="about">
            Qustom Prints about us
            <Link to="/">About</Link>
        </div>
    );
}
