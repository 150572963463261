import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Loading from "../loading/Loading";

export default function usePage() {
    const [pageData, setPageData] = useState();
    const [loading, setLoading] = useState(true);
    const { pageId } = useParams();

    /**
     * useEffect to get a specific category with the correct products.
     *
     */
    useEffect(() => {
        try {
            // Fetch user from api
            fetch(global.api+"pages/"+pageId,  {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json'
                }
            })
                .then((res) => res.json())
                .then((page) => {
                    // If there is something in the response add the response to the state
                    if (page) {
                        setPageData(page.data);
                    }
                });
        } catch (err) {
            return err
        } finally {
            // Finally, set the loading state to false
            setLoading(false);
        }
    }, [pageId])

    return {
        pageData,
        loading
    }
}

