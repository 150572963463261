import React from "react";
import { Link, useHistory } from "react-router-dom";
import useLogin from "../hooks/useLogin";

export function Login() {
  const { onSubmit, onChange, values } = useLogin();
  const history = useHistory();
  return (
    <div className="login container">
      <div className="login__wrapper">
        <div className="login__inner">
          <div className="login__wrapper-form">
            <form className="login__form" onSubmit={onSubmit}>
              <h1 className="login__form-title">Inloggen</h1>
              <p className="login__form-subtitle">
                Etiam a elementum justo. Morbi dignissim turpis velit, eu
                vestibulum erat molestie quis.
              </p>
              <div className="login__form-inner">
                <div className="login__form-input">
                  <h2 className="login__form-inputtitle">Emailadress</h2>
                  <input
                    className="auth-input"
                    placeholder="Email"
                    type="text"
                    value={values.email}
                    name="email"
                    onChange={onChange}
                  />
                </div>
                <div className="login__form-input">
                  <h2 className="login__form-inputtitle">Wachtwoord</h2>
                  <input
                    className="auth-input"
                    placeholder="Password"
                    type="password"
                    value={values.password}
                    name="password"
                    onChange={onChange}
                  />
                </div>
                <div className="login__form-submit">
                  <button
                    className="login__form-button btn btn--primary"
                    type="submit"
                  >
                    <span>
                      Login<i class="ri-arrow-right-line"></i>
                    </span>
                  </button>
                  <p className="login__form-account">
                    Nog geen account?{" "}
                    <Link to="/register" className="login__form-link">
                      Registreer hier
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
