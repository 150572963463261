import React, { createContext, useEffect, useState } from "react";

export const CartContext = createContext({});

/**
 * Cart provider
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CartProvider(props) {
  const [products, setProducts] = useState([]);
  const [showShoppingCartPopUp, setShowShoppingPopUp] = useState(false);

  /**
   * useEffect to set the products state with the data inside to localstorage cart.
   *
   */
  useEffect(() => {
    const storage = localStorage.getItem("cart");
    if (Array.isArray(products) && products.length < 1 && storage) {
      if (storage) {
        setProducts(JSON.parse(storage));
      }
    }
  }, [JSON.stringify(products)]);

  async function getPrice(chosenChoices, productId, key) {
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        options: chosenChoices,
      }),
    };
    try {
      const fetchResponse = await fetch(
        global.api + "products/" + productId + "/price",
        settings
      );
      const data = await fetchResponse.json();
      const storage = JSON.parse(localStorage.getItem("cart"));
      const index = storage.findIndex((item) => item.key === key);
      const newProductState = [...products];
      newProductState[index].price = data.data.price;

      updateLocalStorage(newProductState);
      const newStorage = JSON.parse(localStorage.getItem("cart"));
      setProducts(newStorage);
    } catch (e) {
      return e;
    }
  }

  /**
   * Function to add a product to the shoppingcart.
   *
   * @param {number} productId
   */
  function addToCart(
    product,
    availableChoices,
    chosenChoices,
    price,
    longId,
    uploadedFile,
    selectedAccesoires
  ) {
    const randomKey = getRandomString(14);
    const newState = [
      ...products,
      {
        key: randomKey,
        uploadedFile: uploadedFile,
        longId: longId,
        product: product,
        choices: availableChoices,
        chosenchoises: chosenChoices,
        accesoires: selectedAccesoires,
        amount: availableChoices.Hoeveelheid,
        price: price,
      },
    ];
    setProducts(newState);
    updateLocalStorage(newState);
    setShowShoppingPopUp(!showShoppingCartPopUp);
  }

  function getRandomString(length) {
    var randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var result = "";
    for (var i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }

  /**
   * Fucntion to remove a product from the shoppingcart.
   *
   * @param {number} productId
   */
  function removeFromCart(productId) {
    const newProducts = products.filter((product) => product.key !== productId);

    setProducts(newProducts);
    updateLocalStorage(newProducts);
  }

  /**
   * Functon to updates the shoppingcart in local storage
   *
   * @param {Object} newState
   */
  function updateLocalStorage(newState) {
    localStorage.setItem("cart", JSON.stringify(newState));
  }

  /**
   * Function to increase the amount when an user pressed the plus icon.
   *
   * @param specificationId
   */
  function incrementAmount(productKey) {
    const index = products.findIndex((product) => product.key === productKey);
    const productState = [...products];
    productState[index].amount = parseInt(productState[index].amount) + 1;
    productState[index].choices.Hoeveelheid =
      parseInt(productState[index].choices.Hoeveelheid) + 1;

    // Add amount to the chosenchoises array
    const amount = productState[index].chosenchoises.findIndex(
      (chosenChoice) => chosenChoice.name === "Hoeveelheid"
    );

    productState[index].chosenchoises[amount].value =
      parseInt(productState[index].chosenchoises[amount].value) + 1;

    getPrice(
      productState[index].chosenchoises,
      productState[index].product.id,
      productState[index].key
    );

    setProducts(productState);
    updateLocalStorage(productState);
  }

  /**
   * Function to decrease the amount when an user pressed the minus icon
   *
   * After 1 the product will be removed from the shopping cart.
   *
   * @param specificationId
   */
  function decrementAmount(productKey) {
    const index = products.findIndex((product) => product.key === productKey);
    const productState = [...products];
    productState[index].amount = parseInt(productState[index].amount) - 1;
    productState[index].choices.Hoeveelheid =
      parseInt(productState[index].choices.Hoeveelheid) - 1;

    // Add amount to the chosenchoises array
    const amount = productState[index].chosenchoises.findIndex(
      (chosenChoice) => chosenChoice.name === "Hoeveelheid"
    );

    productState[index].chosenchoises[amount].value =
      parseInt(productState[index].chosenchoises[amount].value) - 1;

    getPrice(
      productState[index].chosenchoises,
      productState[index].product.id,
      productState[index].key
    );

    setProducts(productState);
    updateLocalStorage(productState);

    if (productState[index].amount - 1 < 0) {
      setProducts(products.filter((product) => product.key !== productKey));
    }
  }

  return (
    <CartContext.Provider
      value={{
        addToCart,
        removeFromCart,
        products,
        showShoppingCartPopUp,
        setShowShoppingPopUp,
        incrementAmount,
        decrementAmount,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
}
