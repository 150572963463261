import React, { useContext, useState, useMemo, useRef, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

export function ChoiceAccessoires(props, event) {
  const {
    choiceData,
    availableChoices,
    selectAccesoires,
    choiceName,
    choiceId,
    activeChoice,
    number,
    onSubmit,
    setSelectedAccesoires,
    chosenChoices,
  } = props;
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [choosenNextChoiceId, setChoosenNextChoiceId] = useState();
  const [oldSelectedOptions, setOldSelectedOptions] = useState();
  const [clicked, setClicked] = useState(false);

  function handleClick(name, value, choiceId, choiceOptionId, nextId) {
    const filter = selectedOptions.some((option) => "Geen" === option.name);
    // if option name is the same as "Geen" then reset the state with the new values.
    // else add the new value to the exsisting state.
    if (filter) {
      setSelectedOptions([
        {
          optionName: "accesoires",
          name: value,
          option: choiceData.id,
          choice: choiceOptionId,
          value: 1,
        },
      ]);
    } else {
      // if value param is the same as "Geen" reset the state with the new values.
      // else add the new value to the exsisting state.
      if (value === "Geen") {
        setSelectedOptions([
          {
            optionName: "accesoires",
            name: value,
            option: choiceData.id,
            choice: choiceOptionId,
            value: 1,
          },
        ]);
        setSelectedAccesoires([
          {
            optionName: "accesoires",
            name: value,
            option: choiceData.id,
            choice: choiceOptionId,
            value: 1,
          },
        ]);
        onSubmit(choiceData.next.id, clicked);
      } else {
        setSelectedOptions([
          ...selectedOptions,
          {
            optionName: "accesoires",
            name: value,
            option: choiceData.id,
            choice: choiceOptionId,
            value: 1,
          },
        ]);
        setChoosenNextChoiceId(nextId);
      }
    }
  }

  function handleSubmit(event, name, value, choiceOptionId, nextId) {
    event.preventDefault();
    setClicked(true);
    selectAccesoires(selectedOptions, choosenNextChoiceId, oldSelectedOptions);
    onSubmit(choiceData.next.id, clicked);
  }

  function incrementAmount(choiceName) {
    const index = selectedOptions.findIndex(
      (choice) => choice.name === choiceName
    );
    const amountState = [...selectedOptions];
    amountState[index].value = parseInt(amountState[index].value) + 1;
    setSelectedOptions(amountState);
  }

  function decrementAmount(choiceName) {
    const index = selectedOptions.findIndex(
      (choice) => choice.name === choiceName
    );
    const amountState = [...selectedOptions];
    amountState[index].value = parseInt(amountState[index].value) - 1;
    if (amountState[index].value == 0) {
      const filtered = selectedOptions.filter(
        (option) => option.name !== choiceName
      );
      console.log(filtered);
      setSelectedOptions(
        selectedOptions.filter((option) => option.name !== choiceName)
      );
    } else {
      setSelectedOptions(amountState);
    }
  }

  return (
    <div className="choiceoption choiceaccessoires">
      <div className="choice__heading">
        <div className="choice__heading-number">
          <span>{number}</span>
        </div>
        <span className="choice__heading-title">{choiceData?.name}</span>
        <div className="choice__heading-closebutton">
          <div className="choice__heading-result">
            {/* <p className="result__text">{selectedOption}</p> */}
          </div>
          {activeChoice === choiceData?.id ? (
            <i class="ri-arrow-up-s-line"></i>
          ) : (
            <i class="ri-arrow-down-s-line"></i>
          )}
        </div>
      </div>
      <form
        className="choiceaccessoires__form configurator__choice-card"
        onSubmit={handleSubmit}
      >
        <div className="choiceaccessoires__form-inner">
          {choiceData.choices.map((choice) => {
            const name = choiceName;
            const value = choice?.name;
            const nextId = choice?.next.id;
            const choiceOptionId = choice?.id;
            const filtered = selectedOptions.find(
              (option) => option.name == value
            );
            if (choiceOptionId == "accessories-cross-sell_none") {
              return (
                <div
                  className={`${
                    filtered ? "selected" : "deselected"
                  } choiceoption__heading`}
                  onClick={
                    filtered
                      ? undefined
                      : () =>
                          handleClick(
                            name,
                            value,
                            choiceId,
                            choiceOptionId,
                            nextId
                          )
                  }
                  name={name}
                  value={value}
                >
                  {choice.image ? (
                    <div className="choiceoption__heading-no">
                      <div className="choiceoption__heading-noinner">
                        <div className="choiceoption__heading-content">
                          <h2 className="choiceoption__heading-title">
                            {choice.name}
                          </h2>
                          <p className="choiceoption__heading-subtitle">
                            {choice.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="choiceoption__heading-empty">
                      <div className="choiceoption__heading-none">
                        <h2 className="choiceoption__heading-title">
                          {choice.name}
                        </h2>
                        <p className="choiceoption__heading-subtitle">
                          {choice.description}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            } else {
              return (
                <div
                  className={`${
                    filtered ? "selected" : "deselected"
                  } choiceoption__heading`}
                  onClick={
                    filtered
                      ? undefined
                      : () =>
                          handleClick(
                            name,
                            value,
                            choiceId,
                            choiceOptionId,
                            nextId
                          )
                  }
                  name={name}
                  value={value}
                >
                  {choice.image ? (
                    <div>
                      {filtered ? (
                        <div className="choiceoption__heading-amount">
                          <i
                            onClick={() => decrementAmount(value)}
                            className="ri-subtract-fill"
                          ></i>
                          <p className="choiceoption__heading-count">
                            <span>{filtered.value}</span>
                          </p>
                          <i
                            onClick={() => incrementAmount(value)}
                            className="ri-add-fill"
                          ></i>
                        </div>
                      ) : (
                        <div>
                          <img
                            className="choiceoption__heading-img"
                            src={
                              "https://shop.qustomshop.workers.dev/" +
                              choice.image.url
                            }
                          />
                        </div>
                      )}
                      <div className="choiceoption__heading-content">
                        <h2 className="choiceoption__heading-title">
                          {choice.name}
                        </h2>
                        <p className="choiceoption__heading-subtitle">
                          {choice.description}
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="choiceoption__heading-empty">
                      <div className="choiceoption__heading-none">
                        <h2 className="choiceoption__heading-title">
                          {choice.name}
                        </h2>
                        <p className="choiceoption__heading-subtitle">
                          {choice.description}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
        {selectedOptions ? (
          <button
            className={`${
              clicked ? "clicked" : "notclicked"
            } choice__button btn btn--primary`}
            type="submit"
          >
            <span>Volgende keuze</span>
          </button>
        ) : undefined}
      </form>
    </div>
  );
}
