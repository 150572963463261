import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import Slider from "@material-ui/core/Slider";

export function ChoiceUploadCropper(props) {
  const { originalFile, aspectRatio, croppedPhoto, setCroppedFile } = props;
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [newCroppedArea, setNewCroppedArea] = useState({
    h: 0,
    w: 0,
    x: 0,
    y: 0,
  });
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedFile(calculateCroppedValues(croppedArea));
  }, []);

  function calculateCroppedValues(croppedArea) {
    const h = 100 / ( croppedArea.height / 100);
    const w = 100 / ( croppedArea.width / 100);
    const newState = {
      h: h,
      w: w,
      x: 0 - (croppedArea.x * w) / 100,
      y: 0 - ((100 - (croppedArea.y + croppedArea.height)) * h) / 100,
      orig_h: croppedArea.height,
      orig_y: croppedArea.y,
      orig_w: croppedArea.width,
      orig_x: croppedArea.x,
    };
    return newState;
  }
  console.log(originalFile);
  const aspectRatioFiltered = aspectRatio.split(":");
  return (
    <div className="choiceupload__cropper">
      <div className="crop-container">
        <Cropper
          image={originalFile}
          crop={crop}
          zoom={zoom}
          aspect={aspectRatioFiltered[0] / aspectRatioFiltered[1]}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="choiceupload__cropper-controls">
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="Zoom"
          onChange={(e, zoom) => setZoom(zoom)}
          classes={{ root: "slider" }}
        />
      </div>
    </div>
  );
}
