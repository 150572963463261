import React, {
  useContext,
  useState,
  useMemo,
  useRef,
  useEffect,
  createRef,
} from "react";
import useHookWithRefCallback from "../hooks/useHookWithRefCallback";
export function ChoiceSize(props) {
  const {
    number,
    choiceData,
    availableChoices,
    onChange,
    fetchNewChoiceData,
    choices,
    activeChoice,
    onSubmit,
  } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [clicked, setClicked] = useState(false);
  const [ref] = useHookWithRefCallback();

  //   const inputRef = useRef();
  useEffect(() => {
    choiceData?.multiplechoices.map((choice) => {
      setSelectedOption((v) => ({
        ...v,
        [choice.name]: choice.limits.defaultValue,
      }));
    });
  }, []);

  function handleSubmit(event) {
    event.preventDefault();
    setClicked(true);
    onSubmit(choiceData?.next.id, clicked);
  }

  function handleOnChange(e, optionId, optionName) {
    setSelectedOption((v) => ({ ...v, [e.target.name]: e.target.value }));
    onChange(e, optionId, optionName);
  }

  function mapObject(object, callback) {
    return Object.keys(object).map(function (key) {
      return callback(key, object[key]);
    });
  }
  function setInputFocus(params) {}
  return (
    <div className="choicesize">
      <div className="choice__heading">
        <div className="choice__heading-number">
          <span>{number}</span>
        </div>
        <span className="choice__heading-title">{choiceData?.name}: </span>
        <div className="choice__heading-result">
          {selectedOption ? (
            <div className="choice__heading-option">
              {mapObject(selectedOption, function (key, value) {
                if (choiceData.name === "Hoeveelheid") {
                  return <p className="result__text-amount"> {value} </p>;
                } else {
                  return (
                    <p className="result__text-size">
                      {value}
                      <span>x</span>
                    </p>
                  );
                }
              })}
              {choiceData.name === "Hoeveelheid" ? (
                <span className="result__text-amount">stuks</span>
              ) : (
                <span className="result__text-amount"> cm</span>
              )}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="choice__heading-closebutton">
          {activeChoice === choiceData?.id ? (
            <i class="ri-arrow-up-s-line"></i>
          ) : (
            <i class="ri-arrow-down-s-line"></i>
          )}
        </div>
      </div>
      <form
        className="choicesize__form configurator__choice-card"
        onSubmit={handleSubmit}
      >
        <div className="choicesize__form-inner">
          {choiceData.choices
            ? choiceData.choices.map((choice) => {
                const optionId = choiceData.id;
                const optionName = choiceData.name;
                return (
                  <div className="choicesize__heading">
                    <h2 className="choicesize__heading-title">
                      {choice.name}{" "}
                    </h2>
                    <div className="choicesize__inputs">
                      <input
                        className="auth-input"
                        ref={ref}
                        onChange={(e) =>
                          handleOnChange(e, optionId, optionName)
                        }
                        min={choice.limits.minValue}
                        max={choice.limits.maxValue}
                        id={choice.id}
                        name={choice.name}
                        value={availableChoices?.[choice.name]}
                        optionId={choiceData.id}
                        required
                        type="number"
                      ></input>
                      <em className="auth-input-unit">stuks</em>
                    </div>
                  </div>
                );
              })
            : choiceData.multiplechoices.map((choice) => {
                const optionId = choiceData.id;
                const optionName = choiceData.name;
                return (
                  <div className="choicesize__heading">
                    <h2 className="choicesize__heading-title">
                      {choice.name}{" "}
                    </h2>
                    <div className="choicesize__inputs">
                      <input
                        className="auth-input"
                        ref={ref}
                        onChange={(e) =>
                          handleOnChange(e, optionId, optionName)
                        }
                        min={choice.limits.minValue}
                        max={choice.limits.maxValue}
                        id={choice.id}
                        name={choice.name}
                        value={availableChoices?.[choice.name]}
                        optionId={choiceData.id}
                        required
                        type="number"
                      ></input>
                      <em className="auth-input-unit">
                        {choiceData.name === "Hoeveelheid" ? "stuks" : "cm"}
                      </em>
                    </div>
                  </div>
                );
              })}
        </div>
        <button
          className={`${
            clicked ? "clicked" : "notclicked"
          } choice__button btn btn--primary`}
          type={`${clicked ? "button" : "submit"}`}
        >
          <span>Volgende keuze</span>
        </button>
      </form>
    </div>
  );
}
