import React, { useState } from "react";

export function ChoiceUploadLibraryColors(props) {
  const {
    setSelectedColor,
    selectedColor,
    showColorPicker,
    setShowColorPicker,
  } = props;

  function handleSelectedColor(colorId) {
    setSelectedColor(colorId);
    setShowColorPicker(false);
  }

  function handleShowColorPicker(params) {
    setShowColorPicker(true);
  }

  return (
    <div className="choiceuploadpopup__colors">
      <div
        className="choiceuploadpopup__colors-heading"
        onClick={handleShowColorPicker}
      >
        {selectedColor ? (
          <span>{selectedColor}</span>
        ) : (
          <span>Kleur filter</span>
        )}
        <i className="ri-arrow-drop-down-line"></i>
      </div>
      {showColorPicker ? (
        <div className="choiceuploadpopup__colors-options">
          <div
            className={`${
              selectedColor == "none" ? "noneselected" : "nonedeselected"
            } colorsoptions__list-none`}
            onClick={() => handleSelectedColor("")}
          >
            <span>Geen kleur</span>
          </div>
          <div className="colorsoptions__list">
            <div
              className={`${
                selectedColor == "black_and_white" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(0, 0, 0)" }}
              onClick={() => handleSelectedColor("black_and_white")}
            ></div>{" "}
            <div
              className={`${
                selectedColor == "red" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(244, 78, 59)" }}
              onClick={() => handleSelectedColor("red")}
            ></div>{" "}
            <div
              className={`${
                selectedColor == "purple" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(123, 100, 255)" }}
              onClick={() => handleSelectedColor("purple")}
            ></div>{" "}
            <div
              className={`${
                selectedColor == "magenta" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(171, 20, 158)" }}
              onClick={() => handleSelectedColor("magenta")}
            ></div>
            <div
              className={`${
                selectedColor == "green" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(164, 221, 0)" }}
              onClick={() => handleSelectedColor("green")}
            ></div>
            <div
              className={`${
                selectedColor == "teal" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(104, 204, 202)" }}
              onClick={() => handleSelectedColor("teal")}
            ></div>
            <div
              className={`${
                selectedColor == "blue" ? "active" : "inactive"
              } colorsoptions__list-item`}
              style={{ backgroundColor: "rgb(0, 156, 224)" }}
              onClick={() => handleSelectedColor("blue")}
            ></div>
          </div>
        </div>
      ) : undefined}
    </div>
  );
}
