import React, {useState} from 'react';
import {Link, useHistory} from "react-router-dom";
import { Tab, TabList, Tabs } from 'react-tabs';

export function Payment(props) {
    const {nextTab, addActiveClass} = props;

    function nextStep(nextTab) {
        addActiveClass(nextTab)
    }

    return (
        <div className="payment">
            Payment
            <div className="shoppingcart__button">
                <div className="shoppingcart__button-btn btn btn--primary" onClick={() => nextStep(nextTab)}><span>Betaling<i class="ri-arrow-right-line"></i></span></div>
            </div>
        </div>
    );
}
