import React, { useCallback, useRef } from "react";

export default function useHookWithRefCallback() {
  const ref = useRef(null);
  const setRef = useCallback((node) => {
    if (ref.current) {
      //   console.log(ref);
    }

    if (node) {
      // Check if a node is actually passed. Otherwise node would be null.
      // You can now do what you need to, addEventListeners, measure, etc.
      //   node.current.focus();
    }

    // Save a reference to the node
    ref.current = node;
  }, []);

  return [setRef];
}
