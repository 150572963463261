import calculateAspectRatio from "calculate-aspect-ratio";
import React, { useState } from "react";
import { ChoiceUploadCropper } from "./cropper/ChoiceUploadCropper";

export function ChoiceUploadPreview(props, event) {
  const {
    showChoiceUploadPreview,
    setShowChoiceUploadPreview,
    originalFile,
    uploadedFile,
    width,
    height,
    croppedPhoto,
  } = props;
  const [croppedFile, setCroppedFile] = useState();
  const aspectRatio = calculateAspectRatio(width, height);

  function handleClosePreview() {
    setShowChoiceUploadPreview(false);
    croppedPhoto(croppedFile);
  }

  if (!showChoiceUploadPreview) {
    return null;
  } else {
    return (
      <div className="choiceuploadpreview">
        <div className="choiceuploadpreview__inner">
          <div
            className="choiceuploadpreview__inner-close"
            onClick={() => setShowChoiceUploadPreview(false)}
          >
            <i className="ri-close-line"></i>
            <span>Sluiten</span>
          </div>
          <div
            className="choiceuploadpreview__inner-edit"
            onClick={() => handleClosePreview()}
          >
            <i className="ri-save-line"></i>
            <span>Bijsnijden</span>
          </div>
          <ChoiceUploadCropper
            originalFile={originalFile}
            aspectRatio={aspectRatio}
            croppedPhoto={croppedPhoto}
            setCroppedFile={setCroppedFile}
          />
        </div>
      </div>
    );
  }
}
