import React, {useState} from 'react';
import Loading from '../loading/Loading';

export default function useCategories(props) {
    const [categories, setCategories] = useState();
    const [loading, setLoading] = useState(true);

    /**
     * useEffect to get all categories and the data from each product.
     *
     */
    React.useEffect(() => {
        try {
            // Fetch user from api
            fetch(global.api+"categories",  {
                method: "GET",
                mode: "cors",
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Origin': 'https://shop.qustomshop.workers.dev'
                }
            })
                .then((res) => res.json())
                .then((categories) => {
                    // If there is something in the response add the response to the state
                    if (categories) {
                        setCategories(categories.data);
                    }
                });
        } catch (err) {
            return err
        } finally {
            // Finally, set the loading state to false
            setLoading(false)
        }
    }, [])

    return {
        categories,
        loading
    }
}

