import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import Loading from "../loading/Loading";
import SmallLoading from "../loading/SmallLoading";
import { ChoiceUploadPreview } from "./ChoiceUploadPreview";
import { ChoiceUploadLibraryColors } from "./photolibrary/ChoiceUploadLibraryColors";
import { ChoiceUploadLibraryOrientations } from "./photolibrary/ChoiceUploadLibraryOrientations";
import { ChoiceUploadLibrarySearch } from "./photolibrary/ChoiceUploadLibrarySearch";

const DEFAULT_SEARCH_VALUES = {
  name: "",
};

export function ChoiceUploadPopup(props) {
  const {
    showChoiceUploadPopup,
    setShowChoiceUploadPopup,
    handleSelectedPhoto,
  } = props;
  const [photos, setPhotos] = useState(null);
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [selectedColor, setSelectedColor] = useState("");
  const [selectedOrientation, setSelectedOrientation] = useState("");
  const [searchValues, setSearchValues] = useState(DEFAULT_SEARCH_VALUES);
  const [showColorPicker, setShowColorPicker] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        // Fetch user from api
        await fetch(
          global.api +
            "uploader/library/photos/list?color=" +
            selectedColor +
            "&orientation=" +
            selectedOrientation +
            "&search=" +
            searchValues.name,
          {
            headers: {
              "Content-Type": "application/json",
              accept: "application/json",
            },
          }
        )
          .then((res) => res.json())
          .then((photo) => {
            // If there is something in the response add the response to the state
            if (photo.data) {
              setPhotos(photo.data.photos);
            }
          });
      } catch (err) {
        return err;
      } finally {
        // Finally, set the loading state to false
        setLoading(false);
      }
    }
    fetchData();
  }, [selectedColor, selectedOrientation, searchValues]);

  function clickedSelectedPhoto(photo) {
    setSelectedPhoto(photo.id);
    handleSelectedPhoto(photo);
  }

  function handleDeselectedPhoto(photo) {
    setSelectedPhoto(null);
  }

  function handleSelectedColor(colorId) {
    setSelectedColor(colorId);
    setShowColorPicker(false);
  }

  function handleShowColorPicker(params) {
    setShowColorPicker(true);
  }

  function handleSelectedOrientation(orientationId) {
    setSelectedOrientation(orientationId);
  }

  function handleOnChange(e) {
    setSearchValues((v) => ({ ...v, name: e.target.value }));
  }

  if (!showChoiceUploadPopup) {
    return null;
  } else {
    return (
      <div>
        <div className="choiceuploadpopup">
          <div className="choiceuploadpopup__inner">
            <div
              className="choiceuploadpreview__inner-close"
              onClick={() => setShowChoiceUploadPopup(false)}
            >
              <i class="ri-close-line"></i>
            </div>
            <div className="choiceuploadpopup__heading">
              <h2 className="choiceuploadpopup__heading-title">
                Kies een design uit onze bibliotheek
              </h2>
              <div className="choiceuploadpopup__heading-filters">
                <ChoiceUploadLibrarySearch
                  searchValues={searchValues}
                  handleOnChange={handleOnChange}
                />
                <ChoiceUploadLibraryOrientations
                  selectedOrientation={selectedOrientation}
                  handleSelectedOrientation={handleSelectedOrientation}
                />
                <ChoiceUploadLibraryColors
                  selectedColor={selectedColor}
                  setSelectedColor={setSelectedColor}
                  setShowColorPicker={setShowColorPicker}
                  showColorPicker={showColorPicker}
                />
              </div>
            </div>
            {loading ? (
              <Loading />
            ) : (
              <div className="choiceuploadpopup__list">
                {photos.map((photo) => {
                  return (
                    <div
                      className={`${
                        photo.id == selectedPhoto ? "selected" : "deselected"
                      } choiceuploadpopup__list-item`}
                    >
                      <div className="choiceuploadpopup__list-inner">
                        <div
                          className="choiceuploadpopup__list-picture"
                          style={{
                            backgroundImage: `url("${photo.thumbnail}")`,
                          }}
                          onClick={
                            photo.id == selectedPhoto
                              ? () => handleDeselectedPhoto(photo.id)
                              : () => clickedSelectedPhoto(photo)
                          }
                        ></div>
                        <div
                          className="choiceuploadpopup__list-text"
                          dangerouslySetInnerHTML={{ __html: photo.creditLink }}
                        ></div>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
