import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import useOrder from "../../hooks/useOrder";
import useShoppingCart from "../../hooks/useShoppingCart";
import SmallLoading from "../../loading/SmallLoading";

export function Summary(props) {
  const {
    nextTab,
    addActiveClass,
    allValues,
    setProducts,
    otherAddress,
    deliveryDate,
    completed,
    shoppingCartPrice,
  } = props;
  const [value, setValue] = useState(null);
  const { placeOrder } = useOrder();
  const [productState, setProductState] = useState([]);
  const [orderState, setOrderState] = useState();
  const { products } = useShoppingCart();
  const [allCompleted, setAllCompleted] = useState(false);
  const [showAllSpecifications, setShowAllSpecifications] = useState(false);

  const ref = useRef();

  useEffect(() => {
    setProducts({ products });
    prepareProductState();
    if (allValues) {
      prepareOrderState();
    }

    var result = false;

    for (var i in completed) {
      if (completed[i] === true) {
        result = true;
        break;
      }
    }
    if (result === true) {
      setAllCompleted(true);
    }
  }, [products, allValues]);

  useEffect(() => {
    document.addEventListener("mousedown", onMouseDown);

    return () => document.removeEventListener("mousedown", onMouseDown);
  }, []);

  const onMouseDown = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowAllSpecifications(false);
    }
  };

  function prepareProductState(params) {
    products.map((product) => {
      setProductState((v) => [
        ...v,
        {
          longId: product.longId,
          uploadedFile: product.uploadedFile,
          productId: product.product.id,
          quantity: product.amount,
          options: product.chosenchoises,
        },
      ]);
    });
    // setProductState(newState);
  }

  function prepareOrderState() {
    setOrderState({
      order: {
        id: "",
        invoiceAddress: allValues.invoiceAddress,
        differentDeliveryAddress: otherAddress,
        deliveryAddress: allValues.deliveryAddress,
        deliveryDate: deliveryDate,
      },
      lines: productState,
    });
  }

  function nextStep(nextTab) {
    placeOrder(orderState);
  }

  function mapObject(object, callback) {
    return Object.keys(object).map(function (key) {
      return callback(key, object[key]);
    });
  }

  function mapObjectSliced(object, callback) {
    return Object.keys(object)
      .slice(0, 3)
      .map(function (key) {
        return callback(key, object[key]);
      });
  }
  function allMapObject(object, callback) {
    return Object.keys(object)
      .slice(3)
      .map(function (key) {
        return callback(key, object[key]);
      });
  }

  return (
    <div className="summary">
      <div className="summary__wrapper">
        <div className="summary__products">
          <h2 className="summary__products-title">Al uw producten</h2>
          {products && products.length ? (
            products.map((product, index) => {
              return (
                <div className="summary__products-item">
                  {product.uploadedFile ? (
                    <img
                      className="summary__products-image"
                      src={product.uploadedFile}
                    />
                  ) : (
                    (product.images.length = 0 ? (
                      <img
                        className="shoppingcartpopup__cart-image"
                        src={"../images/placeholder.png"}
                      />
                    ) : (
                      <img
                        className="summary__products-image"
                        src={`https://shop.qustomshop.workers.dev/${product.product.images[0].url}`}
                      />
                    ))
                  )}
                  <div className="summary__products-content">
                    <p className="list__content-title">
                      {product?.product.name}
                    </p>
                    {mapObjectSliced(product.choices, function (key, value) {
                      return (
                        <div className="overview__values">
                          <p className="overview__values-name">{key}: </p>
                          <p className="overview__values-value">{value}</p>
                        </div>
                      );
                    })}
                    {showAllSpecifications === index ? (
                      <div
                        className="list__content-allspecifications"
                        ref={ref}
                      >
                        {allMapObject(product.choices, function (key, value) {
                          return (
                            <div className="overview__values">
                              <p className="overview__values-name">{key}: </p>
                              <p className="overview__values-value">{value}</p>
                            </div>
                          );
                        })}
                        {product.accesoires
                          ? product.accesoires.map((accesoire) => {
                              return (
                                <div className="overview__values">
                                  <p className="overview__values-name">
                                    Accesoires:{" "}
                                  </p>
                                  <p className="overview__values-value">
                                    {accesoire.name}
                                  </p>
                                </div>
                              );
                            })
                          : undefined}
                      </div>
                    ) : undefined}
                    <p
                      className="list__content-specifications"
                      onClick={() => setShowAllSpecifications(index)}
                    >
                      Meer product specificaties zien
                    </p>
                  </div>
                </div>
              );
            })
          ) : (
            <p>Geen producten</p>
          )}
        </div>
        {allValues ? (
          <div className="summary__information">
            <div className="summary__information-inner">
              <div className="summary__content">
                <h3 className="summary__content-title">Afleveradres</h3>
                <div className="summary__values">
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.emailadres}
                  </p>
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.phoneNumber}
                  </p>
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.name}
                  </p>
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.street}{" "}
                    {allValues.deliveryAddress.houseNumber}
                    {allValues.deliveryAddress.houseNumberExt}
                  </p>
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.zipCode}{" "}
                    {allValues.deliveryAddress.city}
                  </p>
                  <p className="summary__values-item">
                    {allValues.deliveryAddress.country}
                  </p>
                </div>
              </div>
              <div className="summary__content">
                <h3 className="summary__content-title">Factuuradres</h3>
                <div className="summary__values">
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.emailadres}
                  </p>
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.phoneNumber}
                  </p>
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.name}
                  </p>
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.street}{" "}
                    {allValues.invoiceAddress.houseNumber}
                    {allValues.invoiceAddress.houseNumberExt}
                  </p>
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.zipCode}{" "}
                    {allValues.invoiceAddress.city}
                  </p>
                  <p className="summary__values-item">
                    {allValues.invoiceAddress.country}
                  </p>
                </div>
              </div>
            </div>
            <div className="summary__deliverydate">
              <h3 className="summary__deliverydate-title">Bezorgdatum</h3>
              <p className="summary__deliverydate-message">
                Wij geven opdracht om uw bestelling op{" "}
                <span className="item__content-day">
                  {moment(deliveryDate).format("dddd D MMMM")}
                </span>{" "}
                te bezorgen
              </p>
            </div>
          </div>
        ) : (
          <div></div>
        )}
      </div>

      <div className="shoppingcart__button">
        {allCompleted === true ? (
          <div className="shoppingcart__button-inner">
            <div
              className="shoppingcart__button-btn btn btn--primary"
              onClick={() => nextStep(nextTab)}
            >
              <span>
                Naar betaling<i class="ri-arrow-right-line"></i>
              </span>
            </div>
            <p className="shoppingcart__button-totalprice">
              Totale prijs:
              {shoppingCartPrice?.data.order.price ? (
                <span>
                  € {shoppingCartPrice?.data?.order?.price.toFixed(2)}
                </span>
              ) : (
                <SmallLoading />
              )}
            </p>
          </div>
        ) : (
          <p>Alle stappen zijn nog niet voltooid</p>
        )}
      </div>
    </div>
  );
}
