import React, { useState } from "react";

export function ChoiceUploadPreviewFull(props, event) {
  const {
    showChoiceUploadPreviewFull,
    setShowChoiceUploadPreviewFull,
    uploadedFile,
  } = props;
  console.log(uploadedFile);
  if (!showChoiceUploadPreviewFull) {
    return null;
  } else {
    return (
      <div className="choiceuploadpreview">
        <div className="choiceuploadpreview__inner">
          <div
            className="choiceuploadpreview__inner-image"
            style={{ backgroundImage: `url(${uploadedFile})` }}
          ></div>
          <div
            className="choiceuploadpreview__inner-close"
            onClick={() => setShowChoiceUploadPreviewFull(false)}
          >
            <i className="ri-close-line"></i>
            <span>Sluiten</span>
          </div>
        </div>
      </div>
    );
  }
}
