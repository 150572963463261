import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  Children,
  useCallback,
} from "react";
import { act } from "react-dom/test-utils";
import { Link, useHistory, useParams, withRouter } from "react-router-dom";
import usePrevious from "../hooks/usePrevious";
import useProduct from "../hooks/useProduct";
import Loading from "../loading/Loading";
import { ChoiceAccessoires } from "./ChoiceAccessoires";
import { ChoiceOption } from "./ChoiceOption";
import { ChoiceSize } from "./ChoiceSize";
import { ChoiceSizeOption } from "./ChoiceSizeOption";
import { ChoiceSummary } from "./ChoiceSummary";
import { ChoiceUpload } from "./ChoiceUpload";

export function Configurator(props) {
  const {
    myRef,
    empty,
    scrollToSectionRef,
    productFirstChoice,
    productId,
    product,
  } = props;
  const [choices, setChoices] = useState([]);
  const [availableChoices, setAvailableChoices] = useState();
  const [chosenChoices, setChosenChoices] = useState([]);
  const [activeChosenChoices, setActiveChosenChoices] = useState([]);
  const [nextChoiceId, setNextChoiceId] = useState();
  const [activeChoice, setActiveChoice] = useState();
  const [customFile, setCustomFile] = useState();
  const [selectedAccesoires, setSelectedAccesoires] = useState();
  const [loading, setLoading] = useState(true);
  const { productLinkId } = useParams();
  const history = useHistory();

  const prevId = usePrevious(product?.id);

  useEffect(() => {
    setChoices([]);
    setChosenChoices([]);
    setAvailableChoices([]);
    fetchNewChoiceData(product?.firstChoice);
  }, [productFirstChoice, product]);

  function mapObject(object, callback) {
    return Object.keys(object).map(function (key) {
      return callback(key, object[key]);
    });
  }

  async function fetchNewChoiceData(choiceId) {
    const settings = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    try {
      // Make a get request to the backend with the next choiceID.
      const fetchResponse = await fetch(
        global.api + "products/" + productLinkId + "/choices/" + choiceId,
        settings
      );

      // Put the response in a const.
      const data = await fetchResponse.json();
      setChoices((v) => [...v, data.data]);

      if (data.data.type === "summary") {
        setActiveChoice(data.data.id);
      } else {
        if (data.data.multiplechoices) {
          data.data.multiplechoices.map((choice) => {
            if (choice.type === "uploader") {
            } else {
              if (choice.type === "parent_size_choice") {
                setAvailableChoices((v) => [{ ...v, [choice.height]: "" }]);
                setAvailableChoices((v) => [{ ...v, [choice.width]: "" }]);
              } else {
                if (choice.limits.defaultValue) {
                  setChosenChoices((v) => [
                    ...v,
                    {
                      name: choice.name,
                      option: data.data.id,
                      optionName: data.data.name,
                      choice: choice.id,
                      value: choice.limits.defaultValue,
                    },
                  ]);
                  setAvailableChoices((v) => ({
                    ...v,
                    [choice.name]: choice.limits.defaultValue,
                  }));
                } else {
                  setAvailableChoices((v) => ({ ...v, [choice.name]: "" }));
                  setChosenChoices((v) => [
                    ...v,
                    {
                      name: choice.name,
                      option: data.data.id,
                      optionName: data.data.name,
                      choice: choice.id,
                      value: "",
                      width: choice.width,
                      height: choice.height
                    },
                  ]);
                }
              }
              setActiveChoice(data.data.id);
              setNextChoiceId(data.data.next.id);
            }
          });
        } else {
          if (data.data.type === "uploader") {
            setActiveChoice(data.data.id);
            setActiveChosenChoices(data.data.id);
            setNextChoiceId(data.data.choices[0].next.id);
          } else {
            if (data.data.type === "accessories_pc") {
              setActiveChoice(data.data.id);
              setActiveChosenChoices(data.data.id);
            } else {
              // gewone optie
              setChosenChoices((v) => [
                ...v,
                {
                  name: data.data.name,
                  option: data.data.id,
                  optionName: data.data.name,
                  choice: "",
                  value: "",
                },
              ]);
              setActiveChoice(data.data.id);
              setActiveChosenChoices(data.data.id);
              setNextChoiceId(data.data.choices[0].next.id);
            }
          }
        }
      }
    } catch (e) {
      return e;
    } finally {
      setLoading(false);
    }
  }

  function onSubmit(choiceNextId, clicked) {
    if (clicked === true) {
      scrollToSectionRef(myRef);
      // setActiveChoice(choiceNextId);
    } else {
      fetchNewChoiceData(choiceNextId);
      scrollToSectionRef(myRef);
    }
  }

  function onChange(e, optionId, optionName) {
    // Set the AvailableChoices with the data from the input fields.
    setAvailableChoices((v) => ({ ...v, [e.target.name]: e.target.value }));

    // Find the index in chosenChoices array that matches the current onChange
    const findIndex = chosenChoices.findIndex(
      (choice) => choice.choice == e.target.id
    );

    // Add the new values to the object that is the same as findIndex
    const newState = [...chosenChoices];
    newState[findIndex] = {
      name: e.target.name,
      option: optionId,
      optionName: optionName,
      choice: e.target.id,
      value: e.target.value,
    };

    // Set the chosenChoices state with the update state called newState
    setChosenChoices(newState);
  }

  function onClick(id, value, choiceId, nextId, valueId, optionName, selectedOption, choosenNextChoiceId, width, height ) {
    // Set the AvailableChoices with the data from the onClick fields.

    const testChocie = choices.findIndex(searchId);
    const chosenChoicesPosition = chosenChoices.findIndex(
      searchIdChosenChoises
    );
console.log("W",width, height)
     if (width) setAvailableChoices((v) => ({ ...v, ["Breedte"]: width }));
     if (height) setAvailableChoices((v) => ({ ...v, ["Hoogte"]: height }));
    setAvailableChoices((v) => ({ ...v, [optionName]: value }));

    if (nextId) {
      if (availableChoices[optionName] === "") {
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: value,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: "",
          },
        ];

        setChosenChoices(newState);
      } else {
        getIndex(testChocie);
        getIndexChosenChoices(chosenChoicesPosition);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: value,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: "",
          },
        ];

        setChosenChoices(newState);
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
      }
    }
    // scrollToSectionRef(myRef);
  }

  function onClickSize(id, value, choiceId, nextId, valueId, optionName) {
    // Set the AvailableChoices with the data from the onClick fields.
    const testChocie = choices.findIndex(searchId);
    const chosenChoicesPosition = chosenChoices.findIndex(
      searchIdChosenChoises
    );
    setAvailableChoices((v) => ({ ...v, [optionName]: value }));

    if (nextId) {
      if (availableChoices[optionName] === "") {
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: optionName,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: value,
          },
        ];

        setChosenChoices(newState);
      } else {
        getIndex(testChocie);
        getIndexChosenChoices(chosenChoicesPosition);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: optionName,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: value,
          },
        ];

        setChosenChoices(newState);
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
      }
    }
  }

  function onClickSizeBoth(
    id,
    valueBoth,
    choiceId,
    nextId,
    valueId,
    optionName
  ) {
    // Set the AvailableChoices with the data from the onClick fields.
    const testChocie = choices.findIndex(searchId);
    const chosenChoicesPosition = chosenChoices.findIndex(
      searchIdChosenChoises
    );

    mapObject(valueBoth, function (key, value) {
      setAvailableChoices((v) => ({ ...v, [key]: value }));
    });

    if (nextId) {
      if (availableChoices[optionName] === "") {
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: optionName,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: valueBoth,
          },
        ];

        setChosenChoices(newState);
      } else {
        getIndex(testChocie);
        getIndexChosenChoices(chosenChoicesPosition);
        const filtered = chosenChoices.filter((choice) => choice.option !== id);
        const newState = [
          ...filtered,
          {
            name: optionName,
            option: id,
            optionName: optionName,
            choice: valueId,
            value: valueBoth,
          },
        ];

        setChosenChoices(newState);
        fetchNewChoiceData(nextId);
        scrollToSectionRef(myRef);
      }
    }
  }

  function selectAccesoires(selectedOptions, choosenNextChoiceId) {
    setSelectedAccesoires(selectedOptions);
  }

  function searchId(choice) {
    return choice.id === activeChoice;
  }

  function enableChoice(choiceId) {
    setActiveChoice(choiceId);
    setActiveChosenChoices(choiceId);
  }

  function getIndex(testChocie) {
    const theKeyPosition = testChocie + 1;
    choices.length = theKeyPosition;
  }

  function searchIdChosenChoises(choice) {
    return choice.option === activeChosenChoices;
  }

  function getIndexChosenChoices(chosenChoicesPosition) {
    const chosenChoicesKeyPosition = chosenChoicesPosition + 1;
    chosenChoices.length = chosenChoicesKeyPosition;
  }

  function handleCustomFile(file) {
    setCustomFile(file);
  }

  return (
    <div id="configurator" className="configurator">
      {loading ? (
        <Loading />
      ) : choices[0] === null ? (
        <Loading />
      ) : (
        choices.map((choice, index) => {
          return (
            <div
              key={choice.id}
              id={choice?.id}
              ref={choice?.id == activeChoice ? myRef : empty}
              className={"configurator__choice"}
              onClick={() => enableChoice(choice?.id)}
            >
              <div
                className={`${
                  choice?.id == activeChoice ? "active" : "inactive"
                }`}
              >
                {choice?.type === "parent_size" ? (
                  <ChoiceSize
                    key={choice.id}
                    number={index + 1}
                    choiceData={choice}
                    availableChoices={availableChoices}
                    activeChoice={activeChoice}
                    onChange={onChange}
                    choices={choices}
                    fetchNewChoiceData={fetchNewChoiceData}
                    onSubmit={onSubmit}
                  />
                ) : (
                  <div></div>
                )}
                {choice?.type === "parent_amount" ? (
                  <ChoiceSize
                    key={choice.id}
                    number={index + 1}
                    choiceData={choice}
                    availableChoices={availableChoices}
                    activeChoice={activeChoice}
                    onChange={onChange}
                    choices={choices}
                    fetchNewChoiceData={fetchNewChoiceData}
                    onSubmit={onSubmit}
                  />
                ) : (
                  <div></div>
                )}
                {choice?.type === "parent" ? (
                  <ChoiceOption
                    key={choice.id}
                    loading={loading}
                    number={index + 1}
                    choiceData={choice}
                    availableChoices={availableChoices}
                    activeChoice={activeChoice}
                    choiceNameId={choice?.id}
                    choiceId={choice?.choices[0]?.next.id}
                    onClick={onClick}
                  />
                ) : (
                  <div></div>
                )}
                {choice?.type === "parent_size_choice" ? (
                  <ChoiceSizeOption
                    key={choice.id}
                    loading={loading}
                    number={index + 1}
                    choiceData={choice}
                    availableChoices={availableChoices}
                    activeChoice={activeChoice}
                    choiceNameId={choice?.id}
                    choiceId={choice?.choices[0]?.next.id}
                    onClickSize={onClickSize}
                    onClickSizeBoth={onClickSizeBoth}
                  />
                ) : (
                  <div></div>
                )}
                {choice?.type === "accessories_pc" ? (
                  <ChoiceAccessoires
                    key={choice.id}
                    number={index + 1}
                    choiceData={choice}
                    chosenChoices={chosenChoices}
                    availableChoices={availableChoices}
                    activeChoice={activeChoice}
                    choiceNameId={choice?.id}
                    choiceId={choice?.choices[0]?.next.id}
                    selectAccesoires={selectAccesoires}
                    onSubmit={onSubmit}
                    setSelectedAccesoires={setSelectedAccesoires}
                  />
                ) : (
                  <div></div>
                )}
                {choice?.type === "uploader" ? (
                  <ChoiceUpload
                    key={choice.id}
                    number={index + 1}
                    choiceData={choice}
                    availableChoices={availableChoices}
                    setAvailableChoices={setAvailableChoices}
                    setChosenChoices={setChosenChoices}
                    chosenChoices={chosenChoices}
                    handleCustomFile={handleCustomFile}
                    activeChoice={activeChoice}
                    choiceNameId={choice?.id}
                    onSubmit={onSubmit}
                    selectedAccesoires={selectedAccesoires}
                  />
                ) : (
                  <div></div>
                )}
              </div>
              {choice?.type === "summary" ? (
                <ChoiceSummary
                  choiceNameId={choice.name}
                  choiceData={choice}
                  availableChoices={availableChoices}
                  activeChoice={activeChoice}
                  chosenChoices={chosenChoices}
                  customFile={customFile}
                  onSubmit={onSubmit}
                  selectedAccesoires={selectedAccesoires}
                />
              ) : (
                <div></div>
              )}
            </div>
          );
        })
      )}
    </div>
  );
}
