import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import useCategories from "../hooks/useCategories";

export default function NavbarBottom() {
  const { categories } = useCategories();
  const [openHover, setOpenHover] = useState();
  const history = useHistory();

  function toggleHover(categoryID, productId) {
    history.push("/" + categoryID + "/" + productId);
    setOpenHover(false);
  }

  function activeHover(categoryId) {
    setOpenHover(categoryId);
  }

  function deactiveHover(params) {
    setOpenHover(false);
  }

  return (
    <nav id="site-header-bottom" className="site-header-bottom">
      <div className="site-header-bottom__wrapper container">
        <div className="site-header-bottom__navigation">
          <ul className="navigation__menu">
            {/* <li className="navigation__menu-item">
                          <Link to="/products" id="navigation__menu-link" className="navigation__menu-link">Alle producten </Link>
                        </li> */}
            {categories?.categories.map((category, index) => (
              <li
                className="navigation__menu-item"
                onMouseOver={() => activeHover(category.id)}
                onMouseLeave={deactiveHover}
                key={index}
              >
                <Link
                  to={"/" + category.id}
                  id="navigation__menu-link"
                  className="navigation__menu-link"
                >
                  {category.name}
                  {/* <div className="navigation__menu-beam"></div> */}
                </Link>
                <div
                  className={`${
                    category.id === openHover ? "open" : "closed"
                  } navigation__menu-hover`}
                >
                  {category.subCategories.map((subcategorie, index) => {
                    return (
                      <div className="menu__hover-sub" key={index}>
                        <p className="menu__hover-title">{subcategorie.name}</p>

                        <ul className="menu__hover">
                          {subcategorie.products.map((product, index) => {
                            return (
                              <li className="menu__hover-list" key={index}>
                                <a
                                  onClick={() =>
                                    toggleHover(category.id, product.id)
                                  }
                                  className="menu__hover-link"
                                >
                                  {product.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    );
                  })}
                  {/* <p className="menu__hover-title">{category.name}</p>
                                    {category.products.map((product, index) =>
                                        <div className="menu__hover" key={index}>
                                            <a onClick={() => history.push("/"+category?.id+"/"+product.id)} className="menu__hover-link">{product.name}</a>
                                        </div>
                                    )} */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}
