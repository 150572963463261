import React, { useState } from "react";
import { Link } from "react-router-dom";
import useHeaderSlider from "../hooks/useHeaderSlider";
import Loading from "../loading/Loading";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

export default function Header() {
  const { items, loading } = useHeaderSlider();

  return (
    <div className="header">
      <div className="header__wrapper container">
        <AliceCarousel
          className="header__glider glider"
          autoPlay="true"
          autoPlayInterval="5000"
          disableDotsControls="false"
          disableButtonsControls="false"
          infinite="true"
        >
          {loading ? (
            <Loading />
          ) : (
            items?.data.items.map((item, index) => (
              <div
                className="header__inner"
                style={{
                  backgroundImage: `url("https://shop.qustomshop.workers.dev/${item.image}")`,
                }}
                key={index}
              >
                <div className="header__inner-overlay"></div>
                <div className="header__heading">
                  <h1 className="header__heading-title">{item.title}</h1>
                  <div
                    className="header__heading-subtitle"
                    dangerouslySetInnerHTML={{ __html: item.story }}
                  ></div>
                  <div className="header__heading-button">
                    <Link
                      to={item.link}
                      className="header__heading-btn btn btn--primary"
                    >
                      <span>
                        {item.linkName}
                        <i className="ri-arrow-right-line"></i>
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
            ))
          )}
        </AliceCarousel>
      </div>
    </div>
  );
}
