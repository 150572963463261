import { useState } from "react";
import { useParams } from "react-router-dom";

export default function useProductPriceCalculation() {
  const [configurationPrice, setConfigurationPrice] = useState();
  const { productLinkId } = useParams();

  async function getPrice(chosenChoices, selectedAccesoires, productId, key) {
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify([
        { options: chosenChoices, accesoires: selectedAccesoires },
      ]),
    };
    try {
      const fetchResponse = await fetch(
        global.api + "products/" + productLinkId + "/price",
        settings
      );
      const data = await fetchResponse.json();
      setConfigurationPrice(data);
      return data;
    } catch (e) {
      return e;
    }
  }

  async function getPriceId(chosenChoices, selectedAccesoires) {
    const settings = {
      method: "POST",
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({
        options: chosenChoices,
        accesoires: selectedAccesoires,
      }),
    };
    try {
      const fetchResponse = await fetch(
        global.api + "products/" + productLinkId + "/price",
        settings
      );
      const data = await fetchResponse.json();
      setConfigurationPrice(data);
      return data;
    } catch (e) {
      return e;
    }
  }

  return {
    getPrice,
    getPriceId,
    configurationPrice,
  };
}
