import React from 'react';
import usePage from '../hooks/usePage';

export default function Page() {
    const {pageData} = usePage();
    return (
        <div id="page" className="page container">
            <div className="page__content"  dangerouslySetInnerHTML={ {__html: pageData?.story} }></div>
        </div>
    )
}