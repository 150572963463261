import React, { useEffect, useState } from "react";
import useContact from "../hooks/useContact";
import usePage from "../hooks/usePage";

export default function PageContact() {
  const [pageData, setPageData] = useState();
  const [loading, setLoading] = useState(true);
  const { values, onChange, onSubmit } = useContact();

  /**
   * useEffect to get a specific category with the correct products.
   *
   */
  useEffect(() => {
    try {
      // Fetch user from api
      fetch(global.api + "pages/contact", {
        headers: {
          "Content-Type": "application/json",
          accept: "application/json",
        },
      })
        .then((res) => res.json())
        .then((page) => {
          // If there is something in the response add the response to the state
          if (page) {
            setPageData(page.data);
          }
        });
    } catch (err) {
      return err;
    } finally {
      // Finally, set the loading state to false
      setLoading(false);
    }
  }, []);

  return (
    <div id="page" className="pagecontact container">
      <div
        className="pagecontact__content"
        dangerouslySetInnerHTML={{ __html: pageData?.story }}
      ></div>

      <div className="pagecontact__form">
        <form className="pagecontact__form-inner" onSubmit={onSubmit}>
          <h1 className="register__form-title">Contacteer ons</h1>
          <div className="register__form-inner">
            <div className="register__form-input">
              <h2 className="register__form-inputtitle">Naam</h2>
              <input
                className="auth-input"
                placeholder="Naam"
                type="text"
                value={values.name}
                name="name"
                onChange={onChange}
                required
              />
            </div>
            <div className="register__form-input">
              <h2 className="register__form-inputtitle">Emailadress</h2>
              <input
                className="auth-input"
                placeholder="Email"
                type="email"
                value={values.email}
                name="email"
                onChange={onChange}
                required
              />
            </div>
            <div className="register__form-input">
              <h2 className="register__form-inputtitle">Uw bericht</h2>
              <textarea
                className="auth-input"
                placeholder="Uw bericht"
                type="text"
                value={values.message}
                name="message"
                onChange={onChange}
                required
              />
            </div>
            <div className="register__form-submit">
              <button
                className="register__form-button btn btn--primary"
                type="submit"
              >
                <span>
                  Versturen<i class="ri-arrow-right-line"></i>
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
